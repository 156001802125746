import {combineReducers, createStore} from 'redux';
import appStateReducer from './appReducer';
import userReducer from './userReducer';
import quizzReducer from './quizzReducer';

const allReducers = combineReducers({
	user:userReducer,
	appState:appStateReducer,
	quizz: quizzReducer
})
let store = createStore(allReducers)
console.log(store.getState());
export default store;