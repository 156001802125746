import React, {Component} from "react";
import { connect } from 'react-redux';
import LoadingIndicator from '../containers/LoadingIndicator';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import CardContent from '@material-ui/core/CardContent';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import AccountCircle from '@material-ui/icons/AccountCircle';
import AlternateEmail from '@material-ui/icons/AlternateEmail';
import Mail from '@material-ui/icons/Mail';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import Divider from '@material-ui/core/Divider';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import "./Contact.css";


class Contact extends Component {
	constructor(props){
	console.log("je suis reconstruit");
    super(props);
    this.state = {
      initPage:"contact",
      name:"",
      password:"",
      email:"",
      contactMessage:"",
      isLoading:true,
      nameInput:"clean",
      passwordInput:"clean",
      repeatPasswordInput:"clean",
      emailInput:"clean",
      currentTab:0,
      showPassword: false,
      showAlert:false,
      mess:"",
      lang:"en"
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleChangeTab = (event, value) => {
    //console.log("Tab changed to : "+value);
    this.setState({currentTab:value,})
  }
  handleSubmit(event){
  	//console.log('A name was submitted: '+ this.state.name);
  	//console.log('A password was submitted: '+ this.state.password);
  	event.preventDefault();
  	if(this.state.name==="" || this.state.password===""){

  	} else{
  		this.props.history.push('/homepage');
  	}
  }

  componentDidMount() {
  	console.log("componentDidMount : ")
      this.setState({
        isLoading: false
      })
  }

  updatePage(initPage){
    //this.setState({page});
    //console.log("HomeBase loaded "+initPage);
    const action = { type:"GOTO_PAGE", value:initPage};
    this.props.dispatch(action);
  }
  componentDidUpdate() {
    //console.log("componentDidUpdate : ")
    console.log(this.state.currentTab);
    this.updatePage('contact');
  }
  handleClickShowPassword = () => {
    this.setState(state => ({ showPassword: !state.showPassword }));
  };

  _displayLoading() {
    if (this.state.isLoading) {
      return (
	    <div>
	      <LoadingIndicator />
	    </div>
      )
    }
  }
validateEmail(email){
  var re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  return re.test(String(email).toLowerCase());

}
  _gotosignUp(){
    //get back data from the fields
    let username = this.state.name;
    var email = this.state.email;
    let contactMessage = this.state.contactMessage;
    var error = false;
    var message = "";

      if(this.state.email===undefined || this.state.email==="" || !this.validateEmail(this.state.email)){
        error= true;
        message = "Email adress is not valid"
      } 
      

    if(this.state.contactMessage===undefined || this.state.contactMessage.length<2){
        error =  true;
        message= "Use the Message Box to leave a Message."
    }
    if(error){
      this.setState({
        showAlert: true,
        mess:message
      })
    } else{
      //else register
      this.setState({isLoading: true})
      var messSucces = "";
        let url = 'https://www.easyjobnetdemo.be/bqapi/webQuiz/contact';
        fetch(url, {
                method: 'POST',
                body: JSON.stringify({"name":username,"email":email, "message":contactMessage})
            })
            .then(response => response.text())
            .then(data => {
              console.log('++----------------++');
                console.log(JSON.parse(data));
                console.log(JSON.parse(data).code);
                console.log('++----------------++');
                if (JSON.parse(data).code === 1) {
                    messSucces = "Your Message has been sent to Bibleminister. You will receive a response in your Mail as soon as possible.";
                    this.setState({
                        showAlert: true,
                        mess:messSucces,
                      isLoading: false});
                    this.props.history.push("/dashboard");
                } else {
                  var messa = JSON.parse(data).message;
                        this.setState({
                          showAlert: true,
                          mess:messa,
                          isLoading : false,
                        })
                }
            })
    }

  }


_displayAlert(){
  return (

<div>

        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={this.state.showAlert}
          autoHideDuration={6000}
          onClose={this.handleClose}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">{this.state.mess}</span>}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={this.handleClose}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />

      </div>
    )
}
  handleClickOpen = () => {
    this.setState({ showAlert: true });
  };

  handleClose = () => {
    this.setState({ showAlert: false });
  };

  _displaysimpleregisterPage() {
    if (!this.state.isLoading) {
      return (
	    <div>
	    <Card className="cardOpacity">
	    <CardContent className="userTab">
	      <form onSubmit={this.handleSubmit}  noValidate autoComplete="off">
            <div className="center">
            <TextField  id="outlined-name" label="Name" value={this.state.name} onChange={this.handleChange('name')} margin="normal" variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton>
                      <AccountCircle />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              />
              </div>
              <div className="center">
                <TextField  id="outlined-adornment-password" variant="outlined" margin="normal" type='email' label="Email" value={this.state.email} onChange={this.handleChange('email')}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton>
                          <AlternateEmail />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <Divider />
              <div className="center">
                <TextField
                  id="outlined-multiline-static" label="Message" rows="4" variant="outlined" margin="normal" type="Text" value={this.state.contactMessage} multiline rows="4" onChange={this.handleChange('contactMessage')}
                />
              </div>
      			<div className="center">
      			<Button variant="outlined" onClick={() => this._gotosignUp()}>
       				SEND
      			</Button>

      			</div>
	      		</form>
	      	</CardContent>
	      </Card>
	    </div>
      )
    }
  }
    render() {
        return (
          <div className="ContainerCenter">
          <Paper square className="PaperOpacity">
          {this.state.showAlert ? this._displayAlert() :""}
            <div>
              {<div className="registerText">Contact BibleMinister</div>
                }
              <AppBar position="static" color="default">
               
                  {this._displaysimpleregisterPage()}
              </AppBar>
            </div>
          </Paper>
          </div>
        );
    }
}




export default (connect())(Contact);