import React, {Component} from "react";
import { connect } from 'react-redux';
import LoadingIndicator from '../containers/LoadingIndicator';
import Button from '@material-ui/core/Button';
import {Link} from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import "./GuestQuizPlay.css";




class GuestQuizPlay extends Component {
    constructor(props){
    super(props);
    this.state = {
      initPage:"Home",
      isLoading:true,
      quizz: [],
      bqwebCat:"",
      bqwebTitle:"",
      password: "flutPassLEX",
      failedfetch: false,
      isListQuiz: false,
      isShowTheme:false,
      isPlaying:true,
      lang:'en',
      username:this.props.username,
      userid:this.props.userid,
      curQuizIndex:0,
      curQuizL:0,
      curAnswer:[],
      curUserAnswer:[],
      respList:[],
      tempUserAnswer:"",
      selectedRep1:false,
      selectedRep2:false,
      selectedRep3:false,
      selectedRep4:false,
      score:[],
      pageCat:"",
      pageCat2:"",
      pageCat3:"",
      nextQuizTitle:"",
      nextQuizId:""
    };
  }
  updatePage(initPage){
    //this.setState({page});
    console.log("GuestQuizPlay loaded "+initPage);
    const action = { type:"GOTO_PAGE", value:initPage};
    this.props.dispatch(action);
  }
    componentDidMount() {
      this.setState({isLoading: true})
      const action = { type:"GOTO_HOME", payload:'quizz'};
      this.props.dispatch(action);
      const {mess} = this.props.match.params
        let quizz = [];
        let username = this.state.username;
        let lang = this.state.lang;
        var bqbibleCategory = localStorage.getItem("webbq_category");
        var bqquizCategory = localStorage.getItem("webbq_quizcat");
        var bqtag1 = localStorage.getItem("webbq_quiztag");
        if(localStorage.getItem("webbq_title")){
          var bqTitle = localStorage.getItem("webbq_title");
        var url = "";
        if(lang==="fr"){
          url = 'https://www.easyjobnetdemo.be/arch/webQuiz/quizFr/getListQuiz';
        }
        if(lang==="blg"){
          url = 'https://www.easyjobnetdemo.be/arch/webQuiz/quizBlg/getListQuiz';
        }
        if(lang==="en"){
          url = 'https://www.easyjobnetdemo.be/bqapi/webQuiz/quizEn/getListTitleQuiz';
        }
        fetch(url, {
                method: 'POST',
                body: JSON.stringify({"username":username, "lang":lang, "bqTitle":bqTitle})
            })
            .then(response => response.text())
            .then(data => {
                if (JSON.parse(data).Quizz.length) {
                  var curAnswer=[];
                  JSON.parse(data).Quizz.map(q => curAnswer.push(JSON.parse(q)['bqRep1']));
                  var curQuizIndex = this.state.curQuizIndex;
                  var resp =  [JSON.parse(JSON.parse(data).Quizz[0])['bqRep1'], JSON.parse(JSON.parse(data).Quizz[0])['bqRep2'], JSON.parse(JSON.parse(data).Quizz[0])['bqRep3'], JSON.parse(JSON.parse(data).Quizz[0])['bqRep4'] ];
                  resp = this.shuffle(resp);
                    this.setState(() => ({
                        quizz: JSON.parse(data)['Quizz'],
                        failedfetch: false,
                        initPage:bqTitle,
                        curQuizL:JSON.parse(data).Quizz.length,
                        curAnswer:curAnswer,
                        respList:resp,
                        pageCat:bqbibleCategory,
                        pageCat2:bqquizCategory,
                        pageCat3:bqtag1
                    }));
                    this.validateList();
                    console.log(this.state.quizz);
                    this.setState({isLoading: false})
                } else {
                    this.setState(() => ({failedfetch: true}));
                }
            })
          }
          else {
            console.log(localStorage.getItem("webbq_category"));
            this.props.history.push("/quizguest");
          }
console.log("GuestQuizPlay loaded "+this.state.username);
  }


  componentDidUpdate() {
    console.log("componentDidUpdate : ")
    console.log(this.props.initPage);
    console.log(this.state.tempUserAnswer);
  }
  
  _displayLoading() {
    if (this.state.isLoading) {
      return (
      <div>
        <LoadingIndicator />
      </div>
      )
    }
  }
  _highlightresp(ans){
    var tempUserAnswer = this.state.tempUserAnswer;
    switch (ans) {
      case 1:
      tempUserAnswer = this.state.respList[0];
        this.setState(() => ({
            selectedRep1:true,
            selectedRep2:false,
            selectedRep3:false,
            selectedRep4:false,
            tempUserAnswer:tempUserAnswer
        })); 
        break;
      case 2:
      tempUserAnswer = this.state.respList[1];
        this.setState(() => ({
            selectedRep1:false,
            selectedRep2:true,
            selectedRep3:false,
            selectedRep4:false,
            tempUserAnswer:tempUserAnswer
        })); 
        break;
      case 3:
      tempUserAnswer = this.state.respList[2];
        this.setState(() => ({
            selectedRep1:false,
            selectedRep2:false,
            selectedRep3:true,
            selectedRep4:false,
            tempUserAnswer:tempUserAnswer
        })); 
        break;
      case 4:
      tempUserAnswer = this.state.respList[3];
        this.setState(() => ({
            selectedRep1:false,
            selectedRep2:false,
            selectedRep3:false,
            selectedRep4:true,
            tempUserAnswer:tempUserAnswer
        })); 
        break;
      default:
        // statements_def
        break;
    }
    
  }
  _showQuizzCat(curQuizIndex){
    console.log(curQuizIndex);
    localStorage.setItem("webbq_title_quest", curQuizIndex);
    var nextQuizIndex = this.state.curQuizIndex + 1;
    if(nextQuizIndex < this.state.curQuizL && this.state.tempUserAnswer!==""){
      var score = this.state.score;
      if(this.state.curAnswer[this.state.curQuizIndex]===this.state.tempUserAnswer){
        console.log("correct response");
        score.push(1);  
      } else{
        console.log("bad response");
        score.push(0);
      }
      var curUserAnswer = this.state.curUserAnswer;
      curUserAnswer.push(this.state.tempUserAnswer);
      
      
        var resp =  [JSON.parse(this.state.quizz[nextQuizIndex])['bqRep1'], JSON.parse(this.state.quizz[nextQuizIndex])['bqRep2'], JSON.parse(this.state.quizz[nextQuizIndex])['bqRep3'], JSON.parse(this.state.quizz[nextQuizIndex])['bqRep4'] ];
        resp = this.shuffle(resp);
        this.setState(() => ({
            curQuizIndex:nextQuizIndex,
            respList:resp,
            selectedRep1:false,
            selectedRep2:false,
            selectedRep3:false,
            selectedRep4:false,
            tempUserAnswer:"",
            curUserAnswer:curUserAnswer
        }));      
      } else {
        if(nextQuizIndex < this.state.curQuizL){}
          else{
        var score = this.state.score;
        if(this.state.curAnswer[this.state.curQuizIndex]===this.state.tempUserAnswer){
          console.log("correct response");
          score.push(1);  
        } else{
          console.log("bad response");
          score.push(0);
        }
        var curUserAnswer = this.state.curUserAnswer;
        curUserAnswer.push(this.state.tempUserAnswer);
            this.setState(() => ({
              curQuizIndex:nextQuizIndex,
              respList:resp,
              selectedRep1:false,
              selectedRep2:false,
              selectedRep3:false,
              selectedRep4:false,
              tempUserAnswer:"",
              curUserAnswer:curUserAnswer,
              isPlaying:false,
              isListQuiz:true
          }));
            this.sendQuizResult();

          }
      }

}
pagelinks(){
  if (this.state.quizz.length > 0 && this.state.isListQuiz){
  return(
        <div className="forceInlineandleft">
          <Link className="myNavLinks" to='/' >Home</Link><span className="myNavLinksspace">&nbsp;/&nbsp;</span>
          <Link className="myNavLinks" to='/quizguest' >Quiz</Link><span className="myNavLinksspace">&nbsp;/&nbsp;</span>
          <Link className="myNavLinks" to='/guestquizcat' >{this.state.pageCat}</Link><span className="myNavLinksspace">&nbsp;/&nbsp;</span>
          <Link className="myNavLinks" to='/guestquiztag' >{this.state.pageCat2}</Link><span className="myNavLinksspace">&nbsp;/&nbsp;</span>
          <Link className="myNavLinks current" to='/guestquizlist' >{this.state.pageCat3}</Link>
        </div>
    )
}
}

  _showreplayQuiz(){
    var bqTitle = localStorage.getItem("webbq_title");
    console.log("go to Guest play quiz for :"+bqTitle);
    //this.props.history.push("/guestquizplay");
    window.location.reload();
}
  _showNextQuiz(){
    var bqTitle = localStorage.getItem("webbq_title");
    console.log("go to Guest play quiz for :"+bqTitle);
    this.props.history.push("/guestquizplay");
}
sendQuizResult(){
        let url = "";
        let username = this.state.username;
        let lang = this.state.lang;
        let userid = this.state.userid;
        var bqTitle = localStorage.getItem("webbq_title");
        var bqbibleCategory = localStorage.getItem("webbq_category");
        var bqquizCategory = localStorage.getItem("webbq_quizcat");
        var bqtag1 = localStorage.getItem("webbq_quiztag");
        var bqhScoreNum = this.state.score.reduce((totScore, q) => totScore + q);
        console.log(bqTitle);
        if(lang==="fr"){
          url = 'https://www.easyjobnetdemo.be/arch/webQuiz/quizFr/getListQuiz';
        }
        if(lang==="blg"){
          url = 'https://www.easyjobnetdemo.be/arch/webQuiz/quizBlg/getListQuiz';
        }
        if(lang==="en"){
          url = 'https://www.easyjobnetdemo.be/bqapi/webQuiz/quizEn/setQuizResult';
        }
        fetch(url, {
                method: 'POST',
                body: JSON.stringify({"username":username, "userid":userid, "lang":lang, "QuizTitle":this.state.initPage, "Score":this.state.score, "response":this.state.curUserAnswer, "bqhQuizTag":bqtag1 , "bqhQuizCat":bqquizCategory, "bqhBibleCat":bqbibleCategory, "bqhScoreNum":bqhScoreNum})
            })
            .then(response => response.text())
            .then(data => {
                console.log(data);
            })

}
shuffle(a) {
    for (let i = a.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [a[i], a[j]] = [a[j], a[i]];
    }
    return a;
}
  validateList() {
        if (this.state.quizz.length > 0 && this.state.isListQuiz) {
          var totScore = 0;
          var totQuiz = this.state.curQuizIndex;
          var Comment = "";
          var verse = "";
          var verseLink ="";
          totScore = this.state.score.reduce((totScore, q) => totScore + q);
          if(totScore == 10){
            Comment = "parfect result";
            verse ="Jer 15:16  Thy words were found, and I did eat them; and thy word was unto me the joy and rejoicing of mine heart: for I am called by thy name, O LORD God of hosts.";
            verseLink="Jer 15:16";
          }
          if(totScore >= 7 && totScore <10){
            Comment = "You can do better";
            verse =   "Thy words have upholden him that was falling, and thou hast strengthened the feeble knees.";
            verseLink = "Job 4:4";
          }
          if(totScore >= 5 && totScore <7){
            Comment = "average Sore, you need to read your bible";
            verse =   "The entrance of thy words giveth light; it giveth understanding unto the simple.";
            verseLink = "Psa 119:130";
          }
          if(totScore >= 0 && totScore <5){
            Comment = "take the reading of the bible seriously";
            verse =   "Hold fast the form of sound words, which thou hast heard of me, in faith and love which is in Christ Jesus.";
            verseLink = "2Tm 1:13 ";
          }
            return (
              <div>
                <div className="myResFlexContainer">
                <div className="finalScore">
                    <Grid container spacing={3}>
                      <Grid item xs>
                      </Grid>
                      <Grid item xs={1}>
                        <Button variant="outlined" size="large" color="primary" onClick={() => this._showreplayQuiz()}>
                            Replay
                          </Button>
                      </Grid>
                      <Grid item xs={6}>
                        <Paper>Your Score:  {totScore} / {totQuiz}</Paper>
                      </Grid>
                      <Grid item xs={1}>
                        <Button variant="outlined" size="large" color="primary" onClick={() => this._showNextQuiz()}>
                            Next
                          </Button>
                      </Grid>
                      <Grid item xs>
                      </Grid>
                    </Grid>
                  <div></div>
                </div>
                    {this.state.quizz.map((q,index) =>
                        <div key={index} className="quizResCard" id={JSON.parse(q)['bqId']}>
                            <div className="quizCardtitle">
                                <p key={JSON.parse(q)['bqId']} className="TitlePlayCardres">
                                    {JSON.parse(q)['bqQuest']}
                                </p>
                            </div>
                            <div className="rowRes">
                              <div className={this.state.score[index]?'goodA':'falseA'}>
                                Your answer : {this.state.curUserAnswer[index]}
                              </div>
                              <div className={this.state.score[index]?'goodAB':'falseAB'} >
                                Correct Answer: {this.state.curAnswer[index]}
                              </div>
                            </div>
                        </div>
                        )}
                  
                  <blockquote cite={verseLink}>{verse}</blockquote>
                    </div>
                </div>    
            )
        }
        if(this.state.quizz.length > 0 && this.state.isPlaying){
          var curQuizIndex = this.state.curQuizIndex;
          return(

              <div>
                <div className="myPlayFlexContainer">

                        <div className="quizPlayCard" id={JSON.parse(this.state.quizz[curQuizIndex])['bqId']}>
                            <div className="quizCardtitle">
                                <div key={JSON.parse(this.state.quizz[curQuizIndex])['bqId']} className="TitlePlayCard">
                                    <div className="myquiztitle"><span className="myquiztitleQ">{curQuizIndex+1}.&nbsp;&nbsp;&nbsp;&nbsp;</span> {JSON.parse(this.state.quizz[curQuizIndex])['bqQuest']}<span className="bibleReference">{JSON.parse(this.state.quizz[curQuizIndex])['bqReference']}</span></div>
                                </div>
                            </div>
                            <div className="row">
                              <div className={this.state.selectedRep1?'selected':'response'} onClick={() => this._highlightresp(1)}>
                                A.&nbsp;&nbsp;&nbsp; {this.state.respList[0]}
                              </div>
                              <div className={this.state.selectedRep2?'selected':'response'}  onClick={() => this._highlightresp(2)}>
                                B.&nbsp;&nbsp;&nbsp; {this.state.respList[1]}
                              </div>
                              <div className={this.state.selectedRep3?'selected':'response'}  onClick={() => this._highlightresp(3)}>
                                C.&nbsp;&nbsp;&nbsp; {this.state.respList[2]}
                              </div>
                              <div className={this.state.selectedRep4?'selected':'response'}  onClick={() => this._highlightresp(4)}>
                                D.&nbsp;&nbsp;&nbsp; {this.state.respList[3]}
                              </div>
                            </div>

                        </div>

                    </div>
                            <div>
                            <Button variant="contained" color="primary" onClick={() => this._showQuizzCat(curQuizIndex)}>
                                {this.state.curQuizIndex === this.state.curQuizL -1? 'Finish' : 'Next'}
                            </Button>
                            </div>
              </div>
            )
        }
    }

    render() {
        return (
            <div className="HomeBase">
            <div className="lander">
            <div className="links">{this.pagelinks()}</div>
            <h3 className="quiztitle">{this.state.initPage}<br></br><span className="quiznbr">This quiz has 10 questions</span></h3>
            
            {this._displayLoading()}

            {this.validateList()}
            </div>
          </div>
        );
    }
}


const mapStateToProps = (state) => {
return {
  initPage:state.appState.page,
  isAuth:state.user.isauth,
  username:state.user.username,
  userid:state.user.userid
}

};
export default connect(mapStateToProps)(GuestQuizPlay);