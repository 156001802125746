import React, {Component} from "react";
import { connect } from 'react-redux';
import LoadingIndicator from '../containers/LoadingIndicator';
import {Link} from 'react-router-dom';
import "./Quizguest.css";




class Quizguest extends Component {
    constructor(props){
    super(props);
    this.state = {
      initPage:"Home",
      isLoading:true,
      quizz: [],
      password: "flutPassLEX",
      failedfetch: false,
      isListQuiz: true,
      isShowTheme:false,
      lang:'en',
      username:"guest",
      userid:this.props.userid
    };
  }
  updatePage(initPage){
    //this.setState({page});
    console.log("DashBoard loaded "+initPage);
    const action = { type:"GOTO_PAGE", value:initPage};
    this.props.dispatch(action);
  }
    componentDidMount() {
      this.setState({isLoading: true})
      const action = { type:"GOTO_HOME", payload:'quizz'};
      this.props.dispatch(action);
      const {mess} = this.props.match.params
      console.log(mess);
    console.log("componentDidMount : ")
        let quizz = [];
        let username = this.props.username;
        let userid = this.props.userid;
        let lang = this.state.lang;
        var url = "";
        if(lang==="fr"){
          url = 'https://www.easyjobnetdemo.be/arch/webQuiz/quizFr/getListQuiz';
        }
        if(lang==="blg"){
          url = 'https://www.easyjobnetdemo.be/arch/webQuiz/quizBlg/getListQuiz';
        }
        if(lang==="en"){
          url = 'https://www.easyjobnetdemo.be/bqapi/webQuiz/quizEn/getListQuiz';
        }
        fetch(url, {
                method: 'POST',
                body: JSON.stringify({"username":username, "lang":lang, "lang":lang, "userId":userid})
            })
            .then(response => response.text())
            .then(data => {
                if (JSON.parse(data).Quizz.length) {
                    this.setState(() => ({
                        quizz: JSON.parse(data)['Quizz'],
                        failedfetch: false
                    }));
                    this.validateList();
                    console.log(this.state.quizz);
                    this.setState({isLoading: false})
                } else {
                    this.setState(() => ({failedfetch: true}));
                }
            })


  }


  componentDidUpdate() {
    console.log("componentDidUpdate : ")
    console.log(this.props.initPage)
  }
  
  _displayLoading() {
    if (this.state.isLoading) {
      return (
      <div>
        <LoadingIndicator />
      </div>
      )
    }
  }
  _showQuizzCat(bibleCat){
    localStorage.setItem("webbq_category", bibleCat);
    console.log("go to Guest quiz list for :"+bibleCat);
    this.props.history.push("/guestquizcat");
}

pagelinks(){
  return(
        <div className="forceInlineandleft">
          <Link className="myNavLinks" to='/' >Home</Link><span className="myNavLinksspace">&nbsp;/&nbsp;</span>
          <Link className="myNavLinks current" to='/quizguest' >Quiz</Link>
        </div>
    )
}


  validateList() {
        if (this.state.quizz.length > 0 && this.state.isListQuiz) {
            return (
              <div className="myQuizguestFlexItem">
                <div className="myCardFlexContainer" id="logincomment">
                  <h4>Please Login if you want to keep your quiz History and Score</h4>
                </div>
                <div className="myQuizguestCardFlexContainer">
                    {this.state.quizz.map(q =>
                        <div className="quizCard" id={JSON.parse(q)['bqbibleCategory']} onClick={() => this._showQuizzCat(JSON.parse(q)['bqbibleCategory'])}>
                            <div className="quizCardtitle">
                                <h1 key={JSON.parse(q)['bqbibleCategory']} className="TitleCard">
                                    {JSON.parse(q)['bqbibleCategory']}
                                </h1>
                            </div>
                            <div className="row">
                              <div  className="col par1">
                                {(JSON.parse(q)['nbrQuizPlay']==undefined || JSON.parse(q)['nbrQuizPlay']==null)?
                                  <b> {JSON.parse(q)['nbrQuizinCat']/10} Quizzes</b>
                                :
                                  <b>{JSON.parse(q)['nbrQuizPlay']} of {JSON.parse(q)['nbrQuizinCat']/10} Played</b>
                                }
                                
                              </div>
                              <div  className="col par2">
                                <span className="centerSpan"> {JSON.parse(q)['nbrQuizinCat']} questions</span>
                              </div>
                            </div>
                        </div>
                        )}
                    </div>
                </div>    
            )
        }
    }

    render() {
        return (
            <div className="HomeBase">
            <div className="lander">
            <div className="links">{this.pagelinks()}</div>
            {this._displayLoading()}

            {this.validateList()}
            </div>
          </div>
        );
    }
}


const mapStateToProps = (state) => {
return {
  userid:state.user.userid,
  username:state.user.username
}

};

export default connect(mapStateToProps)(Quizguest);