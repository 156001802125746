import React, { Component } from 'react';
import { Widget, addResponseMessage, addLinkSnippet, addUserMessage } from 'react-chat-widget';

import 'react-chat-widget/lib/styles.css';
import logo from './user.svg';
import "./WebChat.css";

class WebChat extends Component {

  componentDidMount() {
    addResponseMessage("Welcome to the bibleminister chat!");
  }

  handleNewUserMessage = (newMessage) => {
    console.log(`New message incomig! ${newMessage}`);
    //addResponseMessage(newMessage);
    // Now send the message throught the backend API
  }

  render() {
    return (
      <div>
        <Widget 
        	profileAvatar={logo}
          	title="BIBLEQUIZ CHAT"
          	subtitle="ask a question..."
          	handleNewUserMessage={this.handleNewUserMessage} />
      </div>
    );
  }
}
 
export default WebChat;