import React, { Component } from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import NotFound from "./containers/NotFound";
import HomeBase from "./HomeBase/HomeBase";
import TopMenu from "./TopMenu/TopMenu";
import SignUp from "./SignUp/SignUp";
import Contact from "./Contact/Contact";
import Login from "./Login/Login";
import About from "./About/About";
import AboutPlay from "./About/AboutPlay";
import AboutQuiz from "./About/AboutQuiz";
import AboutFacts from "./About/AboutFacts";
import Dashboard from "./DashBoard/DashBoard";
import Quizguest from "./Guest/Quizguest";
import GuestQuizList from "./Guest/GuestQuizList";
import GuestQuizCat from "./Guest/GuestQuizCat";
import GuestQuizTag1 from "./Guest/GuestQuizTag1";
import GuestQuizPlay from "./Guest/GuestQuizPlay";
import QuizUser from "./User/QuizUser";
import myProfil from "./User/myProfil";
import ReadBible2 from "./ReadBible/ReadBible";
import ReadBible from "./ReadBible/ReadBible2";
import RecoverUser from "./User/RecoverUser";
import AppBar from '@material-ui/core/AppBar';
import { Provider } from 'react-redux';
import Store from './redux/configureStore';
import WebChat from "./WebChat/WebChat";
import Fellowship from "./Fellowship/Fellowship";

import './App.css';


class App extends Component {
  constructor(props){
    super(props);
    this.state = {
      username:"Guest",
      lang:"en",
      page:"Home",
      userRole:1
    };
    this.updateUser = this.updateUser.bind(this);
    this.updateLang = this.updateLang.bind(this);
    this.updateRole = this.updateRole.bind(this);
  }

updateUser(username){
    this.setState({username});
    console.log("state of username in main app updated");
  }
updateLang(lang){
    this.setState({lang});
    console.log("state of lang in main app updated");
  }
updateRole(userRole){
    this.setState({userRole});
    console.log("state of Role in main app updated");
  }

  render() {

    return (
      <Provider store={Store}>
        <div className="App">
          <header className="App-header">
          <BrowserRouter>
          <div>
            <AppBar position="fixed" color="default">
              <TopMenu />
            </AppBar>
            <Switch>
              <Route exact path='/' component={HomeBase} />
              <Route path='/Login' component={Login}/>
              <Route path='/SignUp' component={SignUp}/>
              <Route path='/Contact' component={Contact}/>
              <Route path='/Dashboard' component={Dashboard}/>
              <Route path='/quizguest' component={Quizguest}/>
              <Route path='/guestquizlist' component={GuestQuizList}/>
              <Route path='/guestquizcat' component={GuestQuizCat}/>
              <Route path='/guestquiztag' component={GuestQuizTag1}/>
              <Route path='/guestquizplay' component={GuestQuizPlay}/>
              <Route path='/quizuser' component={QuizUser}/>
              <Route path='/readbible' component={() => { window.location = 'https://bibleminister.net/bible'; return null;} }/>
              <Route path='/readbible2' component={ReadBible2}/>
              <Route path='/recover' component={RecoverUser}/>
              <Route path='/profil' component={myProfil}/>
              <Route path='/about' component={About}/>
              <Route path='/aboutplay' component={AboutPlay}/>
              <Route path='/aboutquiz' component={AboutQuiz}/>
              <Route path='/aboutfacts' component={AboutFacts}/>
              <Route path='/fellowship' component={Fellowship}/>
              <Route path='/mindmaps' component={() => { window.location = 'https://bibleminister.net/mindmaps/'; return null;} }/>
              <Route path='/studies' component={() => { window.location = 'https://bibleminister.net/studies'; return null;} }/>
              <Route path='/videos' component={() => { window.location = 'https://bibleminister.net/videos/'; return null;} }/>
              <Route path='/resourcesold' component={() => { window.location = 'https://bibleminister.net/files/ResourceDocuments/'; return null;} }/>
              <Route component={NotFound}/>
            </Switch>
            </div>
          </BrowserRouter>
          </header>
        </div>
      </Provider>
    );
  }
}



export default App;