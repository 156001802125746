import React from 'react';
import "./ProgressBar.css";

class ProgressBar extends React.Component {
  constructor(props){
    super(props);
    var score = props.score;
    
  }
  state = {
    score: 0,
  };


  render() {
    const progress = this.props.score*10;
    const scoreColor =['red','red','red','red','red','orange', 'yellow','yellow','green','blue','blue'];
    return (
      <div>
         <div className="progressBar">
            <div className="progressBarper" style={{ width: progress + '%', backgroundColor : scoreColor[this.props.score] }}></div>
          </div> 
      </div>
    );
  }
}

export default ProgressBar;