import React, {Component} from "react";
import { connect } from 'react-redux';
import LoadingIndicator from '../containers/LoadingIndicator';
import {Link} from 'react-router-dom';
import "./GuestQuizCat.css";




class GuestQuizCat extends Component {
    constructor(props){
    super(props);
    this.state = {
      initPage:"Home",
      isLoading:true,
      quizz: [],
      books:[],
      booksN:[],
      bqwebCat:"",
      password: "flutPassLEX",
      failedfetch: false,
      isListQuiz: true,
      isShowTheme:false,
      lang:'en',
      username:"guest",
      userid:"",
      leftMenuState:2,
      pageCat:""
    };
  }
  
  updatePage(initPage){
    //this.setState({page});
    console.log("GuestQuizList loaded "+initPage);
    const action = { type:"GOTO_PAGE", value:initPage};
    this.props.dispatch(action);
  }
    componentDidMount() {
      this.setState({isLoading: true})
      const action = { type:"GOTO_HOME", payload:'quizz'};
      this.props.dispatch(action);
      const {mess} = this.props.match.params
        let quizz = [];
        let username = this.props.username;
        let userid = this.props.userid;
        let lang = this.state.lang;
        if(localStorage.getItem("webbq_category")){
          var bqbibleCategory = localStorage.getItem("webbq_category");
        var url = "";
        if(lang==="fr"){
          url = 'https://www.easyjobnetdemo.be/arch/webQuiz/quizFr/getListQuiz';
        }
        if(lang==="blg"){
          url = 'https://www.easyjobnetdemo.be/arch/webQuiz/quizBlg/getListQuiz';
        }
        if(lang==="en"){
          url = 'https://www.easyjobnetdemo.be/bqapi/webQuiz/quizEn/getListCatQuiz';
        }
        fetch(url, {
                method: 'POST',
                body: JSON.stringify({"username":username, "lang":lang, "bqbibleCategory":bqbibleCategory, "userId":userid})
            })
            .then(response => response.text())
            .then(data => {
                if (JSON.parse(data).Quizz.length) {
                  this.getBooksFromQuizz(JSON.parse(data)['Quizz']);
                    this.setState(() => ({
                        quizz: JSON.parse(data)['Quizz'],
                        failedfetch: false
                    }));
                    this.validateList();
                    console.log(this.state.quizz);
                    this.setState({isLoading: false, pageCat:bqbibleCategory})
                } else {
                    this.setState(() => ({failedfetch: true}));
                }
            })
          }
          else {
            console.log(localStorage.getItem("webbq_category"));
            this.props.history.push("/quizguest");
          }

  }

getBooksFromQuizz(qui){
  let tempBooks = [];
  let tempBooksNumbers = [];
  tempBooks = qui.map(q => JSON.parse(q)['bqbooks']);
  tempBooks = tempBooks.filter(this.onlyUnique);
  this.setState(() =>({
    books:tempBooks
  }));

}

onlyUnique(value, index, self) { 
    return self.indexOf(value) === index;
}
_filterQuizzCat(fClass){
  console.log(fClass);
}
  componentDidUpdate() {
    console.log("componentDidUpdate : ")
    console.log(this.props.initPage)
  }
  
  _displayLoading() {
    if (this.state.isLoading) {
      return (
      <div>
        <LoadingIndicator />
      </div>
      )
    }
  }
_showQuizzCat(bqTitle){
    console.log(bqTitle);
    localStorage.setItem("webbq_quizcat", bqTitle);
        console.log("go to Guest play quiz for :"+bqTitle);
    this.props.history.push("/guestquiztag");
}
_displayLeftMenu(){
  return(
      <div className='sidebar'>
    <div className='title'>
      Sidebar
    </div>
    <ul className='nav'>
    <li>All</li>
      {this.state.books.map(b => 
        <li key={b} onClick={() => this._filterQuizzCat(b)}>{b}</li>
        )}
    </ul>
      </div>
    )
}

pagelinks(){
  return(
        <div className="forceInlineandleft">
          <Link className="myNavLinks" to='/' >Home</Link><span className="myNavLinksspace">&nbsp;/&nbsp;</span>
          <Link className="myNavLinks" to='/quizguest' >Quiz</Link><span className="myNavLinksspace">&nbsp;/&nbsp;</span>
          <Link className="myNavLinks current" to='/guestquizcat' >{this.state.pageCat}</Link>
        </div>
    )
}






  validateList() {
        if (this.state.quizz.length > 0 && this.state.isListQuiz) {
            return (
              <div className="myQuizguestFlexItem">
                <div className="myCardFlexContainer">
                    {this.state.quizz.map((q, index) =>


                        <div key={index} className="quizCard" id={JSON.parse(q)['bqquizCategory']} onClick={() => this._showQuizzCat(JSON.parse(q)['bqquizCategory'])}>
                            <div className="quizCardtitle">
                                <h1 key={JSON.parse(q)['bqquizCategory']} className="TitleCard">
                                    {JSON.parse(q)['bqquizCategory']}
                                </h1>
                            </div>
                            <div className="row">
                              <div  className="col par1">
                                {(JSON.parse(q)['nbrQuizPlay']==undefined || JSON.parse(q)['nbrQuizPlay']==null)?
                                  <b> {JSON.parse(q)['nbrQuizinCat']/10} Quizzes</b>
                                :
                                  <b>{JSON.parse(q)['nbrQuizPlay']} of {JSON.parse(q)['nbrQuizinCat']/10} Played</b>
                                }
                              </div>
                              <div  className="col par2">
                                <span className="centerSpan">{JSON.parse(q)['nbrQuizinCat']} questions</span>
                              </div>
                            </div>
                        </div>


                        )}
                    </div>
                </div>    
            )
        }
    }

    render() {
        return (
            <div className="HomeBase">
            <div className="lander">
            <div className="links">{this.pagelinks()}</div>
            {this._displayLoading()}

            {this.validateList()}
            </div>
          </div>
        );
    }
}

const mapStateToProps = (state) => {
return {
  userid:state.user.userid,
  username:state.user.username
}

};

export default connect(mapStateToProps)(GuestQuizCat);