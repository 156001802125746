import React, {Component} from "react";
import { connect } from 'react-redux';
import LoadingIndicator from '../containers/LoadingIndicator'
import "./HomeBase.css";




class HomeBase extends Component {
	  constructor(props){
    super(props);
    this.state = {
      initPage:"home",
      isLoading:true,
      quizz: [],
      failedfetch: false,
      isListQuiz: true,
      isShowTheme:false,
      lang:'fr',
      username:"guest"
    };
        this.showQuizzGuest = this.showQuizzGuest.bind(this);
        this.showBibleGuest = this.showBibleGuest.bind(this);
        this.showStudies = this.showStudies.bind(this);
        this.showMindMaps = this.showMindMaps.bind(this);
        this.showVideos = this.showVideos.bind(this);
  }
  updatePage(initPage){
    //this.setState({page});
    console.log("HomeBase loaded "+initPage);

  }
    componentDidMount() {
    	this.setState({isLoading: false})
  		//console.log("componentDidMount : ");
      const action = { type:"GOTO_HOME", payload:'home'};
      this.props.dispatch(action);
  }


  componentDidUpdate() {
    //console.log("componentDidUpdate : ")
    //console.log(this.props.initPage);
    this.validateList()

  }
    
  showQuizzGuest(){
    console.log("go to Quizz Guest");
    this.props.history.push("/quizguest");
  }

  showStudies(){
    console.log("go to Studies");
    this.props.history.push("/studies");
  }
  showMindMaps(){
    console.log("go to Studies");
    this.props.history.push("/mindmaps");
  }
  showBibleGuest(){
    console.log("go to Quizz Guest");
    this.props.history.push("/readbible");
  }
  showVideos(){
    console.log("go to Videos");
    this.props.history.push("/videos");
  }
  validateList() {
            return (
              <div className="ContainerCenter">

                <div className="myCardFlexContainer">

                        <div className="quizCard" onClick={this.showQuizzGuest}>
                            <div className="quizCardtitle">
                                <h1 className="TitleCard">
                                    BIBLE QUIZ
                                </h1>
                            </div>
                        </div>
                        <div className="quizCard" onClick={this.showMindMaps}>
                            <div className="quizCardtitle">
                                <h1 className="TitleCard">
                                MINDMAPS
                                </h1>
                            </div>
                        </div>
                      <div className="quizCard" onClick={this.showBibleGuest}>
                            <div className="quizCardtitle">
                                <h1 className="TitleCard">
                                    KJV BIBLE
                                </h1>
                            </div>
                        </div>

                        {<div className="quizCard" onClick={this.showVideos}>
                            <div className="quizCardtitle">
                                <h1 className="TitleCard">
                                    VIDEOS
                                </h1>
                            </div>
                        </div>
                      }
                          <div className="quizCard" onClick={this.showStudies}>
                            <div className="quizCardtitle">
                                <h1 className="TitleCard">
                                    BIBLE STUDIES
                                </h1>
                            </div>
                        </div>
                    </div>
  
              </div>
            )
        
    }

  _displayLoading() {
    if (this.state.isLoading) {
      return (
	    <div>
	      <LoadingIndicator />
	    </div>
      )
    }
  }
  _displayOPtions(){
  	if(!this.state.isLoading){
  		return (
	    <div>
	      <LoadingIndicator />
	    </div>
  			)
  	}
  }
    render() {
        return (
            <div className="HomeBase">
            <div className="lander">
            {this._displayLoading()}

            {this.validateList()}
            </div>
          </div>
        );
    }
}

export default connect()(HomeBase);