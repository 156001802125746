import React, {Component} from "react";
import { connect } from 'react-redux';
import LoadingIndicator from '../containers/LoadingIndicator';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import CardContent from '@material-ui/core/CardContent';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Mail from '@material-ui/icons/Mail';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import "./SignUp.css";


class SignUp extends Component {
	constructor(props){
	console.log("je suis reconstruit");
    super(props);
    this.state = {
      initPage:"Signup",
      name:"",
      password:"",
      email:"",
      isLoading:true,
      nameInput:"clean",
      passwordInput:"clean",
      repeatPasswordInput:"clean",
      emailInput:"clean",
      currentTab:0,
      showPassword: false,
      showAlert:false,
      mess:"",
      lang:"en"
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleChangeTab = (event, value) => {
    //console.log("Tab changed to : "+value);
    this.setState({currentTab:value,})
  }
  handleSubmit(event){
  	//console.log('A name was submitted: '+ this.state.name);
  	//console.log('A password was submitted: '+ this.state.password);
    this.state.password = "defaultPass";
  	event.preventDefault();
  	if(this.state.name==="" || this.state.password===""){

  	} else{
  		this.props.history.push('/homepage');
  	}
  }

  componentDidMount() {
  	console.log("componentDidMount : ")
      this.setState({
        isLoading: false
      })
  }

  updatePage(initPage){
    //this.setState({page});
    //console.log("HomeBase loaded "+initPage);
    const action = { type:"GOTO_PAGE", value:initPage};
    this.props.dispatch(action);
  }
  componentDidUpdate() {
    //console.log("componentDidUpdate : ")
    console.log(this.state.currentTab);
    this.updatePage('Signup');
  }
  handleClickShowPassword = () => {
    this.setState(state => ({ showPassword: !state.showPassword }));
  };

  _displayLoading() {
    if (this.state.isLoading) {
      return (
	    <div>
	      <LoadingIndicator />
	    </div>
      )
    }
  }
validateEmail(email){
  var re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  return re.test(String(email).toLowerCase());

}
  _gotoHome(){
  this.props.history.push('/');
}
  _gotosignUp(){
    //get back data from the fields
    this.state.password = "defaultPass";
    let username = this.state.name;
    let password = this.state.password;
    var email = this.state.email;
    let student = this.state.currentTab;
    let lang = this.state.lang;
    var error = false;
    var message = "";
    if(this.state.password===undefined || this.state.password.length<4){
      error = true;
      message = "The password should be at least 4 Characters";

    }
    if(this.state.currentTab===1){
      if(this.state.email===undefined || this.state.email==="" || !this.validateEmail(this.state.email)){

        error= true;
        message = "Email adress is not valid"

      } 
      
    }else{
        email="";
      }
    if(this.state.name===undefined || this.state.name.length<4){

        error =  true;
        message= "The Username should be at least 4 Characters"

    }
    if(error){
      this.setState({
        showAlert: true,
        mess:message
      })
    } else{
      //else register
      this.setState({isLoading: true})
      var messSucces = "";
        let url = 'https://www.easyjobnetdemo.be/bqapi/user/register';
        fetch(url, {
                method: 'POST',
                body: JSON.stringify({"username":username,"password":password,"email":email, "student":student, "lang":lang})
            })
            .then(response => response.text())
            .then(data => {
                console.log(JSON.parse(data));
                console.log(JSON.parse(data).code);
                if (JSON.parse(data).code === "1") {
                  if(student){
                    messSucces = "Thank you for registering as student. A mail with more information was sent to "+email;
                  }
                  else{
                    messSucces = "Thank you for registering."
                  }
                    this.setState({
                        showAlert: true,
                        mess:messSucces,
                      isLoading: false});
                    setTimeout(() => this._successLogin(), 1000);
                    //this.updateUserAuth(1);
                    //this.props.history.push("/dashboard");
                } else {
                  var messa = JSON.parse(data).content;
                        this.setState({
                          showAlert: true,
                          mess:messa,
                          isLoading : false,
                        })
                }
            })
    }

  }


_successLogin(){
  this.setState({isLoading: true})
      var messSucces = "Login Succesful";
      let url = 'https://www.easyjobnetdemo.be/bqapi/user/login';
      fetch(url, {
              method: 'POST',
              body: JSON.stringify({"username":this.state.name,"password":this.state.password})
          })
          .then(response => response.text())
          .then(data => {
              console.log(JSON.parse(data));
              console.log(JSON.parse(data).code);
              if (JSON.parse(data).code === "1") {
                  this.setState({
                      showAlert: true,
                      mess:messSucces,
                    isLoading: false});
                  this.updateUserAuth(JSON.parse(data).role, JSON.parse(data).mail, JSON.parse(data).userid);
                  setTimeout(() => this.props.history.push("/"), 2000);
                  
                 
              } else {
                var messa = JSON.parse(data).content;
                      this.setState({
                        showAlert: true,
                        mess:messa,
                        isLoading : false,
                      })
              }
          })
}
  updateUserAuth(role, mail, userid){
    console.log("updating user auth to role :"+role);
    let username = this.state.name;
    let userpass = this.state.password;
    const action = {type:"AUTH_USER", payload:{'username':username,'password':userpass, 'role':role, 'mail':mail, 'userid':userid}};
    this.props.dispatch(action);
  }
    /*updateUserAuth(role){
    console.log("updating user auth to role :"+role);
    let username = this.state.name;
    let userpass = this.state.password;
    const action = {type:"AUTH_USER", payload:{'username':username,'password':userpass, 'role':role, 'mail':mail, 'userid':userid}};
    //console.log("updating user auth");
    //const action = {type:"AUTH_USER", value:role};
    this.props.dispatch(action);
  }*/

_displayAlert(){
  return (

<div className="snackbar">

        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={this.state.showAlert}
          autoHideDuration={6000}
          onClose={this.handleClose}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">{this.state.mess}</span>}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={this.handleClose}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />

      </div>
    )
}
  handleClickOpen = () => {
    this.setState({ showAlert: true });
  };

  handleClose = () => {
    this.setState({ showAlert: false });
  };

  _displaysimpleregisterPage() {
    if (!this.state.isLoading) {
      return (
	    <div>
	    <Card className="cardOpacity">
	    <CardContent className="userTab">
	      <form onSubmit={this.handleSubmit}  noValidate autoComplete="off">
            <div className="center">
            <TextField
              id="outlined-name"
              label="Username"
              value={this.state.name}
              onChange={this.handleChange('name')}
              margin="normal"
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton>
                      <AccountCircle />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              />
              </div>
              {/* <div className="center">
                <TextField
                  id="outlined-adornment-password"
                  variant="outlined"
                  type={this.state.showPassword ? 'text' : 'password'}
                  label="Password"
                  value={this.state.password}
                  onChange={this.handleChange('password')}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="Toggle password visibility"
                          onClick={this.handleClickShowPassword}>
                          {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div> */}
      			<div className="center">
      			<Button  id="signupbt" variant="outlined" onClick={() => this._gotosignUp()}>
       				SIGN UP
      			</Button>
      			</div>
            <div className="center">
            <Button variant="outlined" onClick={() => this._gotoHome()} id="cancelbt">
              CANCEL
            </Button>
            </div>
	      		</form>
	      	</CardContent>
	      </Card>
	    </div>
      )
    }
  }
  _displaystudentregisterPage() {
    if (!this.state.isLoading) {
      return (
      <div>
      <Card className="cardOpacity">
      <CardContent className="studTab">
        <form onSubmit={this.handleSubmit}  noValidate autoComplete="on">
            <div className="center">
            <TextField
              id="outlined-name"
              label="Username"
              value={this.state.name}
              onChange={this.handleChange('name')}
              margin="normal"
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton>
                      <AccountCircle />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              />
              </div>
            <div className="center">
            <TextField
              id="outlined-email-input"
              label="Email"
              type="email"
              autoComplete="email"
              value={this.state.email}
              onChange={this.handleChange('email')}
              margin="normal"
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton>
                      <Mail />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              />
              </div>
              <div className="center">
                <TextField
                  id="outlined-adornment-password"
                  variant="outlined"
                  type={this.state.showPassword ? 'text' : 'password'}
                  label="Password"
                  value={this.state.password}
                  onChange={this.handleChange('password')}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="Toggle password visibility"
                          onClick={this.handleClickShowPassword}>
                          {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            <div className="center">
            <Button variant="outlined" onClick={() => this._gotosignUp()}>
              SIGN UP
            </Button>
            </div>
            </form>
          </CardContent>
        </Card>
      </div>
      )
    }
  }
    render() {
        return (
          <div className="ContainerCenter">
          <Paper square className="PaperOpacity">
          {this.state.showAlert ? this._displayAlert() :""}
            <div>
              {this.state.currentTab===0?
                  <div className="registerText">Register as User, to have an unique username used for all quizzes and studies. Access to all your quizzes and studies history.</div>:
                  <div className="registerText">Register as Student, you have access to all quizzes and studies and history. Also, progress reports and course assessments. you can also have group and one to one interactions.</div>
                }
              <AppBar position="static" color="default">
               {/* <Tabs
                  value={this.state.value}
                  onChange={this.handleChangeTab}
                  indicatorColor="primary"
                  textColor="primary"
                  fullWidth>
                {this.state.currentTab===0?
                  <Tab label="Register as User" className="actTab tab1"/>:
                  <Tab label="Register as User" className="tab1"/>
                }
                {this.state.currentTab===1?
                  <Tab label="Register as Student" className="actTab tab2"/>:
                  <Tab label="Register as Student"  className="tab2"/>
                }
                </Tabs>*/}
                {this.state.currentTab===0?
                  this._displaysimpleregisterPage():
                  this._displaystudentregisterPage()
                }
              </AppBar>
            </div>
          </Paper>
          </div>
        );
    }
}




export default (connect())(SignUp);