import React, {Component} from "react";
import { connect } from 'react-redux';
import Toolbar from '@material-ui/core/Toolbar';
import ListItem from '@material-ui/core/ListItem';
import Tooltip from '@material-ui/core/Tooltip';
import LoadingIndicator from '../containers/LoadingIndicator';
import {Link} from 'react-router-dom';
import {ReactComponent as HomePng} from '../img/applications.svg';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import "./TopMenu.css";
import { UPDATE_SCORE, LAST_PLAY } from "../redux/actions";


class TopMenu extends Component {
	  constructor(props){
	  	//console.log("je suis reconstruit");
    super(props);
    this.state = {
      initPage:"home", 
      isLoading:true,
      isAuth:0,
      username:"Guest",
      lang:'en',
      openMenu:true,
      showSubmenu:false
    };
        this.openMenu = this.openMenu.bind(this);
        this.openPage = this.openPage.bind(this);
  }

  componentDidMount() {
  	//console.log("componentDidMount : ")
  	//console.log("componentDidMount isAuth : "+this.props.isAuth);
      this.setState({
        isLoading: false
      })
      //if component is mount log the user
      //fetch('https://www.easyjobnetdemo.be/bqapi/bqapi/active', function(data){console.log(data);});
      //fetch('https://api.ipify.org?format=json', function(data){console.log(data);});
      /*var url = 'https://www.easyjobnetdemo.be/bqapi/webQuiz/logUser';
      fetch(url, {
          method: 'POST',
          body: JSON.stringify({"username":this.state.username, "lang":this.state.lang})
      })
      .then(response => response.text())
      .then(data => {
      })*/
  }

openMenu(){
      if(this.state.openMenu){
        this.setState({openMenu:false});
      } else{
        this.setState({openMenu:true});
      }
}
  componentDidUpdate() {
    console.log("componentDidUpdate : ")
    console.log("componentDidUpdate isAuth : "+this.props.isAuth);
    console.log("componentDidUpdate initPage : "+this.props.initPage);
    console.log(this.props.initPage)
  }


  _displayLoading() {
    if (this.state.isLoading) {
      return (
	    <div>
	      <LoadingIndicator />
	    </div>
      )
    }
  }
  _displaySubMenu(){
    if(this.state.showSubmenu){
      return (
      
        <div className="SubMenu">
          <ListItem button className="btn"><Link className="mySubLinks" to='/about' onClick={() => this.openPage('New Testament')}>THIS MINISTRY</Link></ListItem>
          <ListItem button className="btn"><Link className="mySubLinks" to='/aboutFacts' onClick={() => this.openPage('New Testament')}>BIBLE FACTS</Link></ListItem>
          <ListItem button className="btn"><Link className="mySubLinks" to='/aboutQuiz' onClick={() => this.openPage('Old Testament')}>BIBLE QUIZZES</Link></ListItem>
          <ListItem button className="btn"><Link className="mySubLinks" to='/aboutPlay' onClick={() => this.openPage('Whole Bible')}>QUIZ PLAYERS</Link></ListItem>
        </div>
      )
    }
  }


   updateUserAuth(role){
  	console.log("updating user auth");
  	//const action = {type:"AUTH_USER", value:role};
  	//this.props.dispatch(action);
  }

  openSubMenu(){
      if(this.state.showSubmenu){
        this.setState({showSubmenu:false});
      } else{
        this.setState({showSubmenu:true});
      } 
  }

  _displayMenu(){
  	if(!this.state.isLoading){
      console.log("i am on page :"+this.props.initPage);
  		return(
          <div>
            <div className="fstoolbar">
              <Toolbar  className="mytoolbar">
	              <div className="goLeft">
                <div className="forceInline">
		              {this.props.initPage==="home"?
		              	<span><Link className="myLinks" to='/' ><ListItem button className="btn pageon">HOME</ListItem></Link></span>:
		          		<span><Link className="myLinks" to='/' ><ListItem button className="btn pageoff">HOME</ListItem></Link></span>}
                  {this.props.initPage==='quizz'?
                    <span><Link className="myLinks" to='/quizguest'><ListItem button className="btn pageon">QUIZZES</ListItem></Link></span>:
                    <span><Link className="myLinks" to='/quizguest'><ListItem button className="btn">QUIZZES</ListItem></Link></span>}

                    <span><Link className="myLinks" to='/studies'><ListItem button className="btn">BIBLE STUDIES</ListItem></Link></span>
                    <span><Link className="myLinks" to='/mindmaps'><ListItem button className="btn">MINDMAPS</ListItem></Link></span>
                    <span><Link  className="myLinks"  to='/videos'><ListItem button className="btn">VIDEOS</ListItem></Link></span>
                    <span><Link  className="myLinks"  to='/readbible'><ListItem button className="btn">KJV BIBLE</ListItem></Link></span>
                    {/*<span><Link  className="myLinks"  to='/resources'><ListItem button className="btn">RESOURCES</ListItem></Link></span>*/}
                    {this.props.initPage==='contact'?
                    <span><Link className="myLinks" to='/contact'><ListItem button className="btn pageon">CONTACT</ListItem></Link></span>:
                    <span><Link className="myLinks" to='/contact'><ListItem button className="btn">CONTACT</ListItem></Link></span>}
                  {/*this.props.initPage==='about'?
                  <span className="subHover"><Link className="myLinks" to='/about' onClick={() => this.updatePage('about')}><ListItem button className="btn pageon">ABOUT</ListItem></Link>        <div className="SubMenu">
          <ListItem button className="btn"><Link className="mySubLinks" to='/about' onClick={() => this.openPage('New Testament')}>THIS MINISTRY</Link></ListItem>
          <ListItem button className="btn"><Link className="mySubLinks" to='/aboutFacts' onClick={() => this.openPage('New Testament')}>BIBLE FACTS</Link></ListItem>
          <ListItem button className="btn"><Link className="mySubLinks" to='/aboutQuiz' onClick={() => this.openPage('Old Testament')}>BIBLE QUIZZES</Link></ListItem>
          <ListItem button className="btn"><Link className="mySubLinks" to='/aboutPlay' onClick={() => this.openPage('Whole Bible')}>QUIZ PLAYERS</Link></ListItem>
        </div></span>:
                  <span  className="subHover"><Link className="myLinks" to='/about' onClick={() => this.updatePage('about')}><ListItem button className="btn pageoff">ABOUT</ListItem></Link>        <div className="SubMenu">
          <ListItem button className="btn"><Link className="mySubLinks" to='/about' onClick={() => this.openPage('New Testament')}>THIS MINISTRY</Link></ListItem>
          <ListItem button className="btn"><Link className="mySubLinks" to='/aboutFacts' onClick={() => this.openPage('New Testament')}>BIBLE FACTS</Link></ListItem>
          <ListItem button className="btn"><Link className="mySubLinks" to='/aboutQuiz' onClick={() => this.openPage('Old Testament')}>BIBLE QUIZZES</Link></ListItem>
          <ListItem button className="btn"><Link className="mySubLinks" to='/aboutPlay' onClick={() => this.openPage('Whole Bible')}>QUIZ PLAYERS</Link></ListItem>
        </div></span>*/}

		              </div>
		              </div>
		              <div className="goRight">
		              {this.props.isAuth===undefined || this.props.isAuth===0?
                  <div className="forceInline">
                    {this.props.initPage==='login'?
                    <span><Tooltip title="Login to see your personal quiz history" placement="top" leaveDelay={1000}><Link className="myLinks" to='/login' onClick={() => this.updatePage('login')}><ListItem button className="btn pageon">QUIZ LOGIN</ListItem></Link></Tooltip></span>
                    :
                    <span><Tooltip title="Login to see your personal quiz history" placement="top" leaveDelay={1000}><Link className="myLinks" to='/login' onClick={() => this.updatePage('login')}><ListItem button className="btn">QUIZ LOGIN</ListItem></Link></Tooltip></span>
                    }
                    <span>
                    <span><Tooltip title="Register a username to do quizzes if you want to keep your personal quiz history" placement="top" leaveDelay={1000}><Link className="myLinks" to='/SignUp' onClick={() => this.updatePage('login')}><ListItem button className="btn">REGISTER</ListItem></Link></Tooltip></span>
                    </span>

                    </div>
                                      :
                    <div className="forceInline">
                    {this.props.initPage==='account'?
                    <span><Link className="myLinks" to='/profil' onClick={() => this.updatePage('account')}><ListItem button className="btn pageon">{this.props.username}</ListItem></Link></span>:
                    <span><Link className="myLinks" to='/profil' onClick={() => this.updatePage('account')}><ListItem button className="btn">{this.props.username}</ListItem></Link></span>}
                    {this.props.initPage==='account'?
                    <span><Link className="myLinks" to='/profil' onClick={() => this.updatePage('account')}><ListItem button className="btn pageon">My Quiz History</ListItem></Link></span>:
                    <span><Link className="myLinks" to='/profil' onClick={() => this.updatePage('account')}><ListItem button className="btn">My Quiz History</ListItem></Link></span>}
                    <span><Link className="myLinks" to='/' onClick={() => this.updatePage('logout')}><ListItem button className="btn">LOGOUT</ListItem></Link></span>
			          	</div>
			          }
	              </div>
              </Toolbar>
              </div>
              <div className="phonetoolbar">
                <Toolbar>
                <div className="btn">
                  {this.state.openMenu?
                    <Link to='#' onClick={() => this.openMenu()}><ListItem button ><div className="MenuOpen"><HomePng/></div></ListItem></Link>:
                  <Link to='#' onClick={() => this.openMenu()}><ListItem button ><div className="MenuClose"><HomePng/></div></ListItem></Link>}
                  
                  </div>
                  </Toolbar>
                  {this.state.openMenu?
                  <div></div>
                  :
                  <div className="MenuDiv">
                  <ul className="MenuList">
                                    {this.props.initPage==="home"?
                    <span><Link className="myLinks" to='/' onClick={() => this.updatePagefromRes('Home')}><ListItem button className="btn pageon"><span className="respBtn">HOME</span></ListItem></Link></span>:
                  <span><Link className="myLinks" to='/' onClick={() => this.updatePagefromRes('Home')}><ListItem button className="btn pageoff"><span className="respBtn">HOME</span></ListItem></Link></span>}
                    <span><Link className="myLinks"  to='/mindmaps'><ListItem button className="btn pageoff"><span className="respBtn">MINDMAPS</span></ListItem></Link></span>
                    <span><Link className="myLinks"  to='/videos'><ListItem button className="btn pageoff"><span className="respBtn">VIDEOS</span></ListItem></Link></span>
                    <span><Link className="myLinks"  to='/readbible'><ListItem button className="btn pageoff"><span className="respBtn">KJV BIBLE</span></ListItem></Link></span>
                    {/*<span><Link className="myLinks"  to='/resources'><ListItem button className="btn pageoff"><span className="respBtn">RESOURCES</span></ListItem></Link></span>*/}
                  {this.props.isAuth===undefined || this.props.isAuth===0?
                  <div >
                    {this.props.initPage==='Login'?
                    <span><Link className="myLinks" to='/login' onClick={() => this.updatePagefromRes('Login')}><ListItem button className="btn pageon"><span className="respBtn">LOGIN</span></ListItem></Link></span>:
                    <span><Link className="myLinks" to='/login' onClick={() => this.updatePagefromRes('Login')}><ListItem button className="btn"><span className="respBtn">LOGIN</span></ListItem></Link></span>}
                    {this.props.initPage==='contact'?
                    <span><Link className="myLinks" to='/SignUp' onClick={() => this.updatePagefromRes('Login')}><ListItem button className="btn"><span className="respBtn">REGISTER</span></ListItem></Link></span>:
                    <span><Link className="myLinks" to='/SignUp' onClick={() => this.updatePagefromRes('Login')}><ListItem button className="btn"><span className="respBtn">REGISTER</span></ListItem></Link></span>}
                    {this.props.initPage==='Guest'?
                    <span><Link className="myLinks" to='/quizguest' onClick={() => this.updatePagefromRes('quiz')}><ListItem button className="btn"><span className="respBtn">QUIZZES</span></ListItem></Link></span>:

                    <span><Link className="myLinks" to='/quizguest' onClick={() => this.updatePagefromRes('quiz')}><ListItem button className="btn"><span className="respBtn">QUIZZES</span></ListItem></Link></span>}
                    {this.props.initPage==='contact'?
                    <span><Link className="myLinks" to='/contact' onClick={() => this.updatePagefromRes('contact')}><ListItem button className="btn"><span className="respBtn">CONTACT</span></ListItem></Link></span>:
                    <span><Link className="myLinks" to='/contact' onClick={() => this.updatePagefromRes('contact')}><ListItem button className="btn"><span className="respBtn">CONTACT</span></ListItem></Link></span>}


                    </div>
                                      :
                    <div>
                    {this.props.initPage==='Logout'?
                    <span><Link className="myLinks" to='/profil' onClick={() => this.updatePagefromRes('account')}><ListItem button className="btn"><span className="respBtn">{this.props.username}</span></ListItem></Link></span>:
                    <span><Link className="myLinks" to='/profil' onClick={() => this.updatePagefromRes('account')}><ListItem button className="btn"><span className="respBtn">{this.props.username}</span></ListItem></Link></span>}
                    {this.props.initPage==='Logout'?
                    <span><Link className="myLinks" to='/profil' onClick={() => this.updatePagefromRes('account')}><ListItem button className="btn"><span className="respBtn">My Quiz History</span></ListItem></Link></span>:
                    <span><Link className="myLinks" to='/profil' onClick={() => this.updatePagefromRes('account')}><ListItem button className="btn"><span className="respBtn">My Quiz History</span></ListItem></Link></span>}
                    {this.props.initPage==='Guest'?
                    <span><Link className="myLinks" to='/quizguest' onClick={() => this.updatePagefromRes('quiz')}><ListItem button className="btn"><span className="respBtn">QUIZZES</span></ListItem></Link></span>:
                    <span><Link className="myLinks" to='/quizguest' onClick={() => this.updatePagefromRes('quiz')}><ListItem button className="btn"><span className="respBtn">QUIZZES</span></ListItem></Link></span>}
                    {this.props.initPage==='contact'?
                    <span><Link className="myLinks" to='/contact' onClick={() => this.updatePagefromRes('contact')}><ListItem button className="btn"><span className="respBtn">CONTACT</span></ListItem></Link></span>:
                    <span><Link className="myLinks" to='/contact' onClick={() => this.updatePagefromRes('contact')}><ListItem button className="btn"><span className="respBtn">CONTACT</span></ListItem></Link></span>}
                    <span><Link className="myLinks" to='/about' onClick={() => this.updatePagefromRes('logout')}><ListItem button className="btn"><span className="respBtn">LOGOUT</span></ListItem></Link></span>

                  </div>
                }
                  {/*this.props.initPage==='about'?
                  <div  className="subHover"><Link className="myLinks" to='/about' onClick={() => this.updatePagefromRes('About')}>
                  <ListItem button className="btn"><span className="respBtn">ABOUT</span></ListItem></Link><div className="SubMenu">
          <ListItem button className="btn"><Link className="mySubLinks" to='/about' onClick={() => this.updatePagefromRes('About')}>THIS MINISTRY</Link></ListItem>
          <ListItem button className="btn"><Link className="mySubLinks" to='/aboutFacts' onClick={() => this.updatePagefromRes('About')}>BIBLE FACTS</Link></ListItem>
          <ListItem button className="btn"><Link className="mySubLinks" to='/aboutQuiz' onClick={() => this.updatePagefromRes('About')}>BIBLE QUIZZES</Link></ListItem>
          <ListItem button className="btn"><Link className="mySubLinks" to='/aboutPlay' onClick={() => this.updatePagefromRes('About')}>QUIZ PLAYERS</Link></ListItem>
        </div></div>:
                  <div  className="subHover"><Link className="myLinks" to='/about' onClick={() => this.updatePagefromRes('About')}><ListItem button className="btn"><span className="respBtn">ABOUT</span></ListItem></Link><div className="SubMenu">
          <ListItem button className="btn"><Link className="mySubLinks" to='/about' onClick={() => this.updatePagefromRes('About')}>THIS MINISTRY</Link></ListItem>
          <ListItem button className="btn"><Link className="mySubLinks" to='/aboutFacts' onClick={() => this.updatePagefromRes('About')}>BIBLE FACTS</Link></ListItem>
          <ListItem button className="btn"><Link className="mySubLinks" to='/aboutQuiz' onClick={() => this.updatePagefromRes('About')}>BIBLE QUIZZES</Link></ListItem>
          <ListItem button className="btn"><Link className="mySubLinks" to='/aboutPlay' onClick={() => this.updatePagefromRes('About')}>QUIZ PLAYERS</Link></ListItem>
        </div></div>*/}                
                  </ul></div>}
               
              </div>
              </div>
  			)
  	}
  }

updatePage(initpage){
    //this.setState({page});
    console.log("state of Page in main app updated");
    this.props.dispatch({type:'GOTO_HOME', paylaod:initpage})
    if(initpage==='logout'){
	  	//console.log("updating user auth");
	  	//const action = {type:"AUTH_USER", value:0};
	  	//this.props.dispatch(UPDATE_SCORE);
      this.props.dispatch({type:'USER_LOGOUT', paylaod:"Guest"})
    }
    //const action = { type:"GOTO_PAGE", value:initPage};
    //this.props.dispatch(UPDATE_SCORE);
    this.setState({openMenu:true});
  }
updatePagefromRes(initpage){
    this.openMenu();
    //this.setState({page});
    console.log("state of Page in main app updated");
    this.props.dispatch({type:'GOTO_HOME', paylaod:initpage})
    if(initpage==='logout'){
      //console.log("updating user auth");
      //const action = {type:"AUTH_USER", value:0};
      //this.props.dispatch(UPDATE_SCORE);
      this.props.dispatch({type:'USER_LOGOUT', paylaod:"Guest"})
    }
    //const action = { type:"GOTO_PAGE", value:initPage};
    //this.props.dispatch(UPDATE_SCORE);
    this.setState({openMenu:true});
  }
openPage(bibleCat){
    localStorage.setItem("webbq_category", bibleCat);
    console.log("go to Guest quiz list for :"+bibleCat);
    //this.props.history.push("/guestquizlist");
  }
    render() {
    	//console.log(this.props)
        return (
      <div>
        {this._displayLoading()}
        {this._displayMenu()}
      </div>
        );
    }
}

const mapStateToProps = (state) => {
return {
  initPage:state.appState.page,
  isAuth:state.user.isauth,
  username:state.user.username
}

};

export default connect(mapStateToProps)(TopMenu);