import { GOTO_HOME, GOTO_LOGIN, GOTO_ABOUT, GOTO_QUIZ, GOTO_READBIBLE } from "./actions";

const initState = {
	page:"home"
}


function appStateReducer(state =initState, action){
		let nextState
		switch(action.type){
		case GOTO_HOME:
		state= {...state, page:action.payload}
		console.log("appreducer to = "+action.payload);
			return nextState || state
		case GOTO_ABOUT:
		console.log("appreducer to = "+action.type);
			return nextState || state
		case GOTO_LOGIN:
		console.log("appreducer to = "+action.type);
			return nextState || state
		case GOTO_QUIZ:
		console.log("appreducer to = "+action.type);
			return nextState || state
		case GOTO_READBIBLE:
		console.log("appreducer to = "+action.type);
			return nextState || state
		default :
			return state
	}
}
export default appStateReducer;