import React, {Component} from "react";
import { connect } from 'react-redux';
import LoadingIndicator from '../containers/LoadingIndicator';
import {Link} from 'react-router-dom';
import "./GuestQuizList.css";




class GuestQuizList extends Component {
    constructor(props){
    super(props);
    this.state = {
      initPage:"Home",
      isLoading:true,
      quizz: [],
      books:[],
      booksN:[],
      bqwebCat:"",
      password: "flutPassLEX",
      failedfetch: false,
      isListQuiz: true,
      isShowTheme:false,
      lang:'en',
      username:"guest",
      userid:0,
      leftMenuState:2,
      pageCat:"",
      pageCat2:"",
      pageCat3:""
    };
  }
  
  updatePage(initPage){
    //this.setState({page});
    console.log("GuestQuizList loaded "+initPage);
    const action = { type:"GOTO_PAGE", value:initPage};
    this.props.dispatch(action);
  }
    componentDidMount() {
      this.setState({isLoading: true})
      const action = { type:"GOTO_HOME", payload:'quizz'};
      this.props.dispatch(action);
      const {mess} = this.props.match.params
        let quizz = [];
        let username = this.props.username;
        let userid = this.props.userid;
        console.log("my username is : "+username);
        let lang = this.state.lang;
        var bqbibleCategory = localStorage.getItem("webbq_category");
        var bqquizCategory = localStorage.getItem("webbq_quizcat");
        if(localStorage.getItem("webbq_quiztag")){
          var bqtag1 = localStorage.getItem("webbq_quiztag");
        var url = "";
        if(lang==="fr"){
          url = 'https://www.easyjobnetdemo.be/arch/webQuiz/quizFr/getListQuiz';
        }
        if(lang==="blg"){
          url = 'https://www.easyjobnetdemo.be/arch/webQuiz/quizBlg/getListQuiz';
        }
        if(lang==="en"){
          url = 'https://www.easyjobnetdemo.be/bqapi/webQuiz/quizEn/getListquizCat2';
        }
        fetch(url, {
                method: 'POST',
                body: JSON.stringify({"username":username, "lang":lang, "bqtag1":bqtag1, "userid":userid})
            })
            .then(response => response.text())
            .then(data => {
                if (JSON.parse(data).Quizz.length) {
                  this.getBooksFromQuizz(JSON.parse(data)['Quizz']);
                    this.setState(() => ({
                        quizz: JSON.parse(data)['Quizz'],
                        failedfetch: false
                    }));
                    this.validateList();
                    console.log(this.state.quizz);
                    this.setState({isLoading: false, pageCat:bqbibleCategory, pageCat2:bqquizCategory, pageCat3:bqtag1})
                } else {
                    this.setState(() => ({failedfetch: true}));
                }
            })
          }
          else {
            console.log(localStorage.getItem("webbq_category"));
            this.props.history.push("/quizguest");
          }

  }

getBooksFromQuizz(qui){
  let tempBooks = [];
  let tempBooksNumbers = [];
  tempBooks = qui.map(q => JSON.parse(q)['bqbooks']);
  tempBooks = tempBooks.filter(this.onlyUnique);
  this.setState(() =>({
    books:tempBooks
  }));

}

onlyUnique(value, index, self) { 
    return self.indexOf(value) === index;
}
_filterQuizzCat(fClass){
  console.log(fClass);
}
  componentDidUpdate() {
    console.log("componentDidUpdate : ")
    console.log(this.props.initPage)
  }
  
  _displayLoading() {
    if (this.state.isLoading) {
      return (
      <div>
        <LoadingIndicator />
      </div>
      )
    }
  }
  _showQuizzCat(bqTitle, index){
    console.log(bqTitle);
    console.log(index);
    if(index==this.state.quizz.length-1){
      localStorage.removeItem("webbq_title_next");
    } else{
      localStorage.setItem("webbq_title_next", JSON.parse(this.state.quizz[index+1])['bqTitle']);
    }
    localStorage.setItem("webbq_title", bqTitle);
        console.log("go to Guest play quiz for :"+bqTitle);
    this.props.history.push("/guestquizplay");
}
_displayLeftMenu(){
  return(
      <div className='sidebar'>
    <div className='title'>
      Sidebar
    </div>
    <ul className='nav'>
    <li>All</li>
      {this.state.books.map(b => 
        <li key={b} onClick={() => this._filterQuizzCat(b)}>{b}</li>
        )}
    </ul>
      </div>
    )
}


pagelinks(){
  return(
        <div className="forceInlineandleft">
          <Link className="myNavLinks" to='/' >Home</Link><span className="myNavLinksspace">&nbsp;/&nbsp;</span>
          <Link className="myNavLinks" to='/quizguest' >Quiz</Link><span className="myNavLinksspace">&nbsp;/&nbsp;</span>
          <Link className="myNavLinks" to='/guestquizcat' >{this.state.pageCat}</Link><span className="myNavLinksspace">&nbsp;/&nbsp;</span>
          <Link className="myNavLinks" to='/guestquiztag' >{this.state.pageCat2}</Link><span className="myNavLinksspace">&nbsp;/&nbsp;</span>
          <Link className="myNavLinks current" to='/guestquizlist' >{this.state.pageCat3}</Link>
        </div>
    )
}

cal(res){
  var score  = res.split("|").reduce((a, c) => parseInt(a)+parseInt(c));
  return score;
}
divide(numerator, denominator){
  return Math.round(parseInt(numerator)/parseInt(denominator));
}


  validateList() {
        if (this.state.quizz.length > 0 && this.state.isListQuiz) {
            return (
              <div className="myQuizguestFlexItem">
                <div className="myCardFlexContainer">
                    {this.state.quizz.map((q, index) =>

                        <div key={index} className={JSON.parse(q)['bqbooks']+" gquizListCard"}  id={JSON.parse(q)['bqTitle']} onClick={() => this._showQuizzCat(JSON.parse(q)['bqTitle'],index)}>
                            
                              {JSON.parse(q)['bqhScoreNum']!== null?
                                <div className="quizListCardtitle quizDone">
                                  <p key={JSON.parse(q)['bqTitle']} className="qListTitleCard">{JSON.parse(q)['bqTitle']} <span className="scoreDone"><p>{JSON.parse(q)['bqhScoreMax']}/10</p></span></p>
                                  <span className="tooltiptext">Your Best score is {JSON.parse(q)['bqhScoreMax']}/10<p>Your average score is {Math.round(JSON.parse(q)['bqhScoreAvg'])}/10</p><p>Played {JSON.parse(q)['numofPlay']} time(s)</p></span>
                                </div>
                                :
                                <div className="quizListCardtitle">
                                <h1 key={JSON.parse(q)['bqTitle']} className="qListTitleCard">{JSON.parse(q)['bqTitle']}</h1>
                              </div>
                            }

                            
                        </div>


                        )}
                    </div>
                </div>    
            )
        }
    }

    render() {
        return (
            <div className="HomeBase">
            <div className="lander">
            <div className="links">{this.pagelinks()}</div>
            {this._displayLoading()}

            {this.validateList()}
            </div>
          </div>
        );
    }
}



const mapStateToProps = (state) => {
return {
  userid:state.user.userid,
  username:state.user.username
}

};

export default connect(mapStateToProps)(GuestQuizList);