/*
 * action types
 */

 export const GOTO_HOME = "GOTO_HOME";
 export const GOTO_LOGIN = "GOTO_LOGIN";
 export const GOTO_ABOUT = "GOTO_ABOUT";
 export const GOTO_QUIZ = "GOTO_QUIZ";
 export const GOTO_READBIBLE = "GOTO_READBIBLE";

 export const USER_LOGIN = 'USER_LOGIN';
 export const AUTH_USER ='AUTH_USER';
 export const USER_LOGOUT ='USER_LOGOUT';
 
 export const LAST_PLAY = 'LAST_PLAY';
 export const UPDATE_SCORE = 'UPDATE_SCORE';

