import React, {Component} from "react";
import { connect } from 'react-redux';
import LoadingIndicator from '../containers/LoadingIndicator'
import "./DashBoard.css";




class DashBoard extends Component {
    constructor(props){
    super(props);
    this.state = {
      initPage:"Home",
      isLoading:true,
      quizz: [],
      password: "flutPassLEX",
      failedfetch: false,
      isListQuiz: true,
      isShowTheme:false,
      lang:'fr',
      username:"guest"
    };
  }
  updatePage(initPage){
    //this.setState({page});
    console.log("DashBoard loaded "+initPage);
    const action = { type:"GOTO_PAGE", value:initPage};
    this.props.dispatch(action);
  }
    componentDidMount() {
      this.setState({isLoading: true})
      const {mess} = this.props.match.params
      console.log(mess);
    console.log("componentDidMount : ")
        let quizz = [];
        let username = this.state.username;
        let lang = this.state.lang;
        var url = "";
        if(lang==="fr"){
          url = 'https://www.easyjobnetdemo.be/arch/webQuiz/quizFr/getListQuiz';
        }
        if(lang==="blg"){
          url = 'https://www.easyjobnetdemo.be/arch/webQuiz/quizBlg/getListQuiz';
        }
        if(lang==="en"){
          url = 'https://www.easyjobnetdemo.be/arch/webQuiz/quizEn/getListQuiz';
        }
        fetch(url, {
                method: 'POST',
                body: JSON.stringify({"username":username, "lang":lang})
            })
            .then(response => response.text())
            .then(data => {
                console.log(JSON.parse(data)['Quizz']);
                console.log(JSON.parse(data).Quizz);
                if (JSON.parse(data).Quizz.length) {
                    console.log(JSON.parse(data)['Quizz']);
                    var allbooks = [];
                    JSON.parse(data).Quizz.map(v =>{
                      allbooks.push(JSON.parse(v)['bqBook']);
                    });
                    console.log("ALL BOOKS");
                    console.log(allbooks);
                    console.log("ALL BOOKS");
                    var unBooks = Array.from(new Set(allbooks));
        
                    this.setState(() => ({
                        quizz: JSON.parse(data)['Quizz'],
                        books: unBooks,
                        failedfetch: false
                    }));
                    this.validateList();
                    console.log(this.state.quizz);
                    this.setState({isLoading: false})
                } else {
                    this.setState(() => ({failedfetch: true}));
                }
            })


  }


  componentDidUpdate() {
    console.log("componentDidUpdate : ")
    console.log(this.props.initPage)
  }
  
  _displayLoading() {
    if (this.state.isLoading) {
      return (
      <div>
        <LoadingIndicator />
      </div>
      )
    }
  }
  _showQuizzTheme(){

}
  validateList() {
        if (this.state.quizz.length > 0 && this.state.isListQuiz) {
          console.log("BOOKS");
          console.log(this.state.books);
          console.log("END BOOKS");
            return (
              <div className="myFlexItem">
                <div className="myCardFlexContainer">
                    {this.state.books.map(q =>
                        <div className="quizCard" id={q} onClick={this._showQuizzTheme()}>
                            <div className="quizCardtitle">
                                <h1 key={q} className="TitleCard">
                                    {q}
                                </h1>
                            </div>
                            <div className="row">
                              <div  className="col par1">
                                <b>120/200</b> Played
                              </div>
                              <div  className="col par2">
                                <b>70% </b> Score
                              </div>
                            </div>
                        </div>
                        )}
                    </div>
                </div>    
            )
        }
    }

    render() {
        return (
            <div className="HomeBase">
            <div className="lander">
            <h1>{this.state.initPage}</h1>
            {this._displayLoading()}

            {this.validateList()}
            </div>
          </div>
        );
    }
}

export default connect()(DashBoard);