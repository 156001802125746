import { USER_LOGIN, AUTH_USER, USER_LOGOUT } from "./actions";

const initState = {
	username:"Guest",
	userid:0,
	usermail:"",
	userrole:"",
	isauth:0
}

function saveStatetoLocal(stateObj){
	localStorage.setItem("webquiz_user", JSON.stringify(stateObj));
}
function loadStatetoLocal(stateObj){
	if(localStorage.getItem("webquiz_user")){
		return JSON.parse(localStorage.getItem("webquiz_user"));
	} else{
		localStorage.setItem("webquiz_user", JSON.stringify(stateObj));
		return stateObj;
	}
	
}

function userReducer(state =initState, action){
	let nextState
		switch(action.type){
		case USER_LOGIN:
		console.log("userreducer to = "+action.type);
			return nextState || state
		case AUTH_USER:
		state = {...state, username:action.payload.username, usermail:action.payload.mail, userid:action.payload.userid, userrole:action.payload.role, isauth:1};
		saveStatetoLocal(state);
			return nextState || state
		case USER_LOGOUT:
		state = {...state, username:"Guest", usermail:"", userid:"", userrole:"", isauth:0};
		saveStatetoLocal(state);
			return nextState || state
		default :
		state = loadStatetoLocal(state);
			return state
	}
}
export default userReducer;