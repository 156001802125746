import React, {Component} from "react";
import { connect } from 'react-redux';
import LoadingIndicator from '../containers/LoadingIndicator';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import "./Login.css";

class Login extends Component {
	constructor(props){
	console.log("je suis reconstruit");
    super(props);
    this.state = {
      initPage:"login",
      name:"",
      password:"",
      isLoading:true,
      nameInput:"clean",
      passwordInput:"clean",
			showPassword: false,
			showAlert:false,
      mess:"",
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
	};
	handleClose = () => {
    this.setState({ showAlert: false });
  };
	_displayAlert(){
		return (
	
	<div className="snackbar">
	
					<Snackbar
						anchorOrigin={{
							vertical: 'top',
							horizontal: 'center',
						}}
						open={this.state.showAlert}
						autoHideDuration={6000}
						onClose={this.handleClose}
						ContentProps={{
							'aria-describedby': 'message-id',
						}}
						message={<span id="message-id">{this.state.mess}</span>}
						action={[
							<IconButton
								key="close"
								aria-label="Close"
								color="inherit"
								onClick={this.handleClose}
							>
								<CloseIcon />
							</IconButton>,
						]}
					/>
	
				</div>
			)
	}
  handleSubmit(event){
  	console.log('A name was submitted: '+ this.state.name);
	  this.state.password = "defaultPass"
  	event.preventDefault();
  	if(this.state.name==="" || this.state.password===""){

  	} else{
			//
	this.setState({isLoading: true})
      var messSucces = "Login Succesful";
			let url = 'https://www.easyjobnetdemo.be/bqapi/user/login';
			fetch(url, {
							method: 'POST',
							body: JSON.stringify({"username":this.state.name,"password":this.state.password})
					})
					.then(response => response.text())
					.then(data => {
							console.log(JSON.parse(data));
							console.log(JSON.parse(data).code);
							if (JSON.parse(data).code === "1") {
									this.setState({
											showAlert: true,
											mess:messSucces,
										isLoading: false});
									this.updateUserAuth(JSON.parse(data).role, JSON.parse(data).mail, JSON.parse(data).userid);
									setTimeout(() => this.props.history.push("/"), 2000);
							} else {
								var messa = JSON.parse(data).content;
											this.setState({
												showAlert: true,
												mess:messa,
												isLoading : false,
											})
							}
					})

		}
  }

  componentDidMount() {
  	console.log("componentDidMount : ")
      this.setState({
        isLoading: false
      })
      const action = { type:"GOTO_HOME", payload:'login'};
      this.props.dispatch(action);
  }

  updatePage(initPage){
  }
  updateUserAuth(role, mail, userid){
  	console.log("updating user auth to role :"+role);
  	let username = this.state.name;
  	let userpass = this.state.password;
  	const action = {type:"AUTH_USER", payload:{'username':username,'password':userpass, 'role':role, 'mail':mail, 'userid':userid}};
  	this.props.dispatch(action);
  }
  componentDidUpdate() {
    console.log("componentDidUpdate : ")
    console.log(this.props.initPage);
    this.updatePage('Login');
  }


  _displayLoading() {
    if (this.state.isLoading) {
      return (
	    <div>
	      <LoadingIndicator />
	    </div>
      )
    }
  }

  _gotosignUp(){
  	this.props.history.push('/signUp');
  }
  _gotoHome(){
	this.props.history.push('/');
}
_gotoRecover(){
	this.props.history.push('/recover');
}
    handleClickShowPassword = () => {
    this.setState(state => ({ showPassword: !state.showPassword }));
  };
  _displayLoginPage() {
  	
    if (!this.state.isLoading) {
      return (
	    <div>
	    <Card className="cardOpacity">
	    <CardContent>
	      <form onSubmit={this.handleSubmit}  noValidate autoComplete="off">
		        <div className="center">
		        <TextField
		          id="outlined-name"
		          label="Username"
		          value={this.state.name}
		          onChange={this.handleChange('name')}
		          margin="normal"
		          variant="outlined"
		          InputProps={{
		            endAdornment: (
		              <InputAdornment position="end">
		                <IconButton>
		                  <AccountCircle />
		                </IconButton>
		              </InputAdornment>
		            ),
		          }}
		          />
		          </div>
		          {/* <div className="center">
				        <TextField
				          id="outlined-adornment-password"
				          variant="outlined"
				          type={this.state.showPassword ? 'text' : 'password'}
				          label="Password"
				          value={this.state.password}
				          onChange={this.handleChange('password')}
				          InputProps={{
				            endAdornment: (
				              <InputAdornment position="end">
				                <IconButton
				                  aria-label="Toggle password visibility"
				                  onClick={this.handleClickShowPassword}>
				                  {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
				                </IconButton>
				              </InputAdornment>
				            ),
				          }}
				        />
		          </div> */}
		          <div className="center">
		        <Button variant="outlined" type="submit" value="Submit" id="loginbt">
       				<span className="btnClass">LOGIN</span>
      			</Button>

      			</div>
      			<div className="center">

      			</div>
      			<div className="center">
      			<Button variant="outlined" onClick={() => this._gotosignUp()}  id="registerbt">
       				<span className="btnClass">REGISTER</span>
      			</Button>
      			</div> 
      			<div className="center">
      			<Button variant="outlined" onClick={() => this._gotoHome()}  id="cancelbt">
       				CANCEL
      			</Button>
      			</div>
	      		</form>
	      	</CardContent>
	      </Card>
	    </div>
      )
    }
  }

    render() {
        return (
        <div className="ContainerCenter">
            <div className="LoginPage">
						{this.state.showAlert ? this._displayAlert() :""}
            	{this._displayLoading()}
        		{this._displayLoginPage()}
          </div>
          </div>
        );
    }
}

const mapStateToProps = (state) => {
	console.log(state);
	console.log("mapStateToProps called with state :"+state);
	return {
		isAuth: state
	}
};
export default connect()(Login);