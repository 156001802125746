import React, {Component} from "react";
import { connect } from 'react-redux';
import LoadingIndicator from '../containers/LoadingIndicator';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import Mail from '@material-ui/icons/Mail';
import "./RecoverUser.css";

class RecoverUser extends Component {
	constructor(props){
	console.log("je suis reconstruit");
    super(props);
    this.state = {
      initPage:"login",
      email:"",
      password:"",
      isLoading:true,
      nameInput:"clean",
      passwordInput:"clean",
			showPassword: false,
			showAlert:false,
      mess:"",
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange = email => event => {
    this.setState({
      [email]: event.target.value,
    });
	};
	handleClose = () => {
    this.setState({ showAlert: false });
  };
    _gotoHome(){
  this.props.history.push('/');
}
	_displayAlert(){
		return (
	
	<div>
	
					<Snackbar
						anchorOrigin={{
							vertical: 'top',
							horizontal: 'center',
						}}
						open={this.state.showAlert}
						autoHideDuration={6000}
						onClose={this.handleClose}
						ContentProps={{
							'aria-describedby': 'message-id',
						}}
						message={<span id="message-id">{this.state.mess}</span>}
						action={[
							<IconButton
								key="close"
								aria-label="Close"
								color="inherit"
								onClick={this.handleClose}
							>
								<CloseIcon />
							</IconButton>,
						]}
					/>
	
				</div>
			)
	}
  handleSubmit(event){
  	console.log('A name was submitted: '+ this.state.name);
  	console.log('A password was submitted: '+ this.state.password);
  	event.preventDefault();
  	if(this.state.email===""){

  	} else{
			//
			this.setState({isLoading: true})
      var messSucces = "A new password was sent to :"+this.state.email+" Please log in with the new password.";
			let url = 'https://www.easyjobnetdemo.be/bqapi/user/recoverPass';
			fetch(url, {
							method: 'POST',
							body: JSON.stringify({"email":this.state.email})
					})
					.then(response => response.text())
					.then(data => {
							console.log(JSON.parse(data));
							console.log(JSON.parse(data).code);
							if (JSON.parse(data).code === "1") {
									this.setState({
											showAlert: true,
											mess:messSucces,
										isLoading: false});
									this.updateUserAuth(JSON.parse(data).role, JSON.parse(data).mail, JSON.parse(data).userid);
									this.props.history.push("/");
							} else {
								var messa = JSON.parse(data).content;
											this.setState({
												showAlert: true,
												mess:messa,
												isLoading : false,
											})
							}
					})

		}
  }

  componentDidMount() {
  	console.log("componentDidMount : ")
      this.setState({
        isLoading: false
      })
      const action = { type:"GOTO_HOME", payload:'login'};
      this.props.dispatch(action);
  }

  componentDidUpdate() {
    console.log("componentDidUpdate : ")
    console.log(this.props.initPage);
  }


  _displayLoading() {
    if (this.state.isLoading) {
      return (
	    <div>
	      <LoadingIndicator />
	    </div>
      )
    }
  }
  _displayLoginPage() {
  	
    if (!this.state.isLoading) {
      return (
	    <div>
	    <Card className="cardOpacity">
	    <CardContent>
	      <form onSubmit={this.handleSubmit}  noValidate autoComplete="off">
            <div className="center">
            <TextField
              id="outlined-email-input"
              label="Email"
              type="email"
              autoComplete="email"
              value={this.state.email}
              onChange={this.handleChange('email')}
              margin="normal"
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton>
                      <Mail />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              />
              </div>
      			<div className="center">
      			<Button variant="outlined" type="submit" value="Submit">
       				RECOVER MY PASSWORD
      			</Button>
      			</div>
            <div className="center">
            <Button variant="outlined" onClick={() => this._gotoHome()}>
              CANCEL
            </Button>
            </div>
	      		</form>
	      	</CardContent>
	      </Card>
	    </div>
      )
    }
  }

    render() {
        return (
        <div className="ContainerCenter">
            <div className="LoginPage">
						{this.state.showAlert ? this._displayAlert() :""}
            	{this._displayLoading()}
        		{this._displayLoginPage()}
          </div>
          </div>
        );
    }
}

const mapStateToProps = (state) => {
	console.log(state);
	console.log("mapStateToProps called with state :"+state);
	return {
		isAuth: state
	}
};
export default connect()(RecoverUser);