import { UPDATE_SCORE, LAST_PLAY } from "./actions";

const initState = {
	score:0,
	last_play:""
}

function quizzReducer(state = initState, action){
		let nextState
		switch(action.type){
		case UPDATE_SCORE:
		console.log("quizzreducer to = "+action.type);
			return nextState || state
		case LAST_PLAY:
		console.log("quizzreducer to = "+action.type);
		state = {...state, last_play:action.payload}
			return state
		default :
			return state
	}
}
export default quizzReducer;