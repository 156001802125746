import React, {Component} from "react";
import { connect } from 'react-redux';
import LoadingIndicator from '../containers/LoadingIndicator'
import "./AboutQuiz.css";

class AboutQuiz extends Component {
	  constructor(props){
    super(props);
    this.state = {
      initPage:"about",
      isLoading:true,
      quizz: [],
      failedfetch: false,
      isListQuiz: true,
      isShowTheme:false,
      lang:'en',
      username:"guest",
      pageContent:""
    };
  }
  updatePage(initPage){
    //this.setState({page});
    console.log("About loaded "+initPage);
    const action = { type:"GOTO_PAGE", value:initPage};
    this.props.dispatch(action);
  }
    componentDidMount() {
  		console.log("componentDidMount : ")
  		this.setState({isLoading: false})
      const action = { type:"GOTO_HOME", payload:'about'};
      this.props.dispatch(action);
      let username = this.state.username;
      let lang = this.state.lang;
      var url = "";
      if(lang==="fr"){
          url = 'https://www.easyjobnetdemo.be/arch/webQuiz/quizFr/getListQuiz';
        }
        if(lang==="blg"){
          url = 'https://www.easyjobnetdemo.be/arch/webQuiz/quizBlg/getListQuiz';
        }
        if(lang==="en"){
          url = 'https://www.easyjobnetdemo.be/bqapi/webQuiz/quizEn/getAboutQuiz';
        }
        fetch(url, {
                method: 'POST',
                body: JSON.stringify({"username":username, "lang":lang})
            })
            .then(response => response.text())
            .then(data => {
                if (data.length) {

                    console.log(this.state.quizz);
                    this.setState({isLoading: false, pageContent:data})
                } else {
                    this.setState(() => ({failedfetch: true}));
                }
            })



  }


  componentDidUpdate() {
    console.log("componentDidUpdate : ")
    console.log(this.props.initPage);
	
  }
  

  _displayLoading() {
    if (this.state.isLoading) {
      return (
	    <div>
	      <LoadingIndicator />
	    </div>
      )
    }
  }
      rawMarkup(){
        var rawMarkup = this.state.pageContent;
        return { __html: rawMarkup };
    }
  _displayAboutPage(){
  	if(!this.state.isLoading){
  		return (
	    <div className="aboutPage">
	      <span dangerouslySetInnerHTML={this.rawMarkup()} />
	    </div>
  			)
  	}
  }
    render() {
        return (
            <div className="ContainerCenter">

            <div className="About">
            {this._displayLoading()}

            {this._displayAboutPage()}

          </div>
</div>
        );
    }
}

export default connect()(AboutQuiz);