import React, {Component} from "react";
import CircularProgress from '@material-ui/core/CircularProgress';
//import LinearProgress from '@material-ui/core/LinearProgress';
import "./LoadingIndicator.css";

class LoadingIndicator extends Component {
	render() {
        return (
	    <div>
	      <CircularProgress color="secondary" />
	    </div>
	   )
	}
}

export default LoadingIndicator;