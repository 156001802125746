import React, {Component} from "react";
import { connect } from 'react-redux';
import LoadingIndicator from '../containers/LoadingIndicator';
import ProgressBar from '../containers/ProgressBar';
import {Link} from 'react-router-dom';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import "./myProfil.css";




class myProfil extends Component {
    constructor(props){
    super(props);
    this.state = {
      initPage:"Home",
      isLoading:true,
      quizz: [],
      password: "flutPassLEX",
      failedfetch: false,
      isListQuiz: true,
      isShowTheme:false,
      lang:'en',
      username:this.props.username,
      userid:this.props.userid,
      isAuth:this.props.isAuth
    };
      const columns = [{
          Header: 'Name',
          accessor: 'name' // String-based value accessors!
          }, {
            Header: 'Age',
            accessor: 'age',
            Cell: props => <span className='number'>{props.value}</span> // Custom cell components!
          }, {
            id: 'friendName', // Required because our accessor is not a string
            Header: 'Friend Name',
            accessor: d => d.friend.name // Custom value accessors!
          }, {
            Header: props => <span>Friend Age</span>, // Custom header components!
            accessor: 'friend.age'
          }]
  
  }
  updatePage(initPage){
    //this.setState({page});
    console.log("DashBoard loaded "+initPage);
    const action = { type:"GOTO_PAGE", value:initPage};
    this.props.dispatch(action);
  }
    componentDidMount() {
      this.setState({isLoading: true})
      const action = { type:"GOTO_HOME", payload:'quizuser'};
      this.props.dispatch(action);
      const {mess} = this.props.match.params
      console.log(mess);
    console.log("componentDidMount : ")
        let quizz = [];
        let username = this.props.username;
        let lang = this.state.lang;
        let userId = this.state.userid;
        var url = "";
        if(lang==="fr"){
          url = 'https://www.easyjobnetdemo.be/arch/webQuiz/quizFr/getListQuiz';
        }
        if(lang==="blg"){
          url = 'https://www.easyjobnetdemo.be/arch/webQuiz/quizBlg/getListQuiz';
        }
        if(lang==="en"){
          url = 'https://www.easyjobnetdemo.be/bqapi/webQuiz/quizEn/getListQuizforUser';
        }

        if(this.props.isAuth){
        fetch(url, {
                method: 'POST',
                body: JSON.stringify({"username":username, "lang":lang, "userId":userId})
            })
            .then(response => response.text())
            .then(data => {
                if (JSON.parse(data).Quizz.length) {
                    this.setState(() => ({
                        quizz: JSON.parse(data)['Quizz'],
                        failedfetch: false
                    }));
                    this.validateList();
                    console.log(this.state.quizz);
                    this.setState({isLoading: false})
                } else {
                    this.setState(() => ({failedfetch: true}));
                }
            })
          }
        else{
          this.props.history.push("/guestquiz");
        }

  }


  componentDidUpdate() {
    console.log("componentDidUpdate : ")
    console.log("user : "+this.state.userid)
    console.log(this.props.initPage)
  }
  
  _displayLoading() {
    if (this.state.isLoading) {
      return (
      <div>
        <LoadingIndicator />
      </div>
      )
    }
  }
  _showQuizzRes(bqhId){
    console.log("go to Guest quiz list for :"+bqhId);

}

pagelinks(){
  return(
        <div className="forceInlineandleft">
          <Link className="myNavLinks" to='/' >Home</Link><span className="myNavLinksspace">&nbsp;/&nbsp;</span>
          <Link className="myNavLinks current" to='/profil' >My Quiz History</Link>
        </div>
    )
}

cal(res){
  var score  = res.split("|").reduce((a, c) => parseInt(a)+parseInt(c));
  return score;
}

  validateList() {
        if (this.state.quizz.length > 0 && this.state.isListQuiz) {
            return (
              <div className="myQuizguestFlexItem">
                <div className="myQuizguestCardFlexContainer">
                <h3>Lastest Quiz Results</h3>
                    {this.state.quizz.map(q =>
                        <div className="quizCard" key={JSON.parse(q)['bqhId']} onClick={() => this._showQuizzRes(JSON.parse(q)['bqhId'])}>
                            <div className="quizCardtitle">
                                <h1 key={JSON.parse(q)['bqhQuizId']} className="TitleCardprofil">
                                    {JSON.parse(q)['bqhQuizId']}
                                </h1>
                            </div>
                            <div className="row">
                            <ProgressBar score={this.cal(JSON.parse(q)['bqhScore'])} />
                              <div  className="col par1profil">

                                 Score <b>{this.cal(JSON.parse(q)['bqhScore'])}/10 </b>
                              </div>
                              <div  className="col par2profil">
                                <div className="centerprofil">{JSON.parse(q)['bqhDplayed']}</div>
                              </div>
                            </div>
                        </div>
                        )}
                    </div>
                </div>    
            )
        }
    }

    render() {
        return (
            <div className="HomeBase">
            <div className="lander">
            <div className="links">{this.pagelinks()}</div>
            {this._displayLoading()}
            <div className="profilPage">
              <div className="profilPageHist">
                {this.validateList()}
              </div>
              <div>

              </div>
            </div>
            </div>
          </div>
        );
    }
}


const mapStateToProps = (state) => {
return {
  isAuth:state.user.isauth,
  username:state.user.username,
  userid:state.user.userid
}

};

export default connect(mapStateToProps)(myProfil);