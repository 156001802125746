import React, {Component} from "react";
import myBible from '../json/bibleMartin1744.json'
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';

import "./ReadBible.css";

class ReadBible extends Component {
      constructor(props) {
        super(props);

        this.state = {
            user: [],
            clients: [],
            testament:0,
            book:0,
            chapter:0,
            verse:1,
            isReadBible:true,
            isLoading:false,
            isChooseBible:false,
            isChooseBook:false,
            isChooseChapter:false,
            isChooseNotes:false,
            defaultVersesize:13,
            nomLivre:'',
            booksListfr:['Genèse', 'Exode', 'Lévitique', 'Nombres', 'Deutéronome', 'Josué', 'Juges', 'Ruth', '1 Samuel', '2 Samuel', '1 Rois', '2 Rois', '1 Chroniques', '2 Chroniques', 'Ezra', 'Néhémie', 'Esther', 'Job', 'Psaume', 'Proverbes', 'Ecclesiastes', 'Song of Solomon', 'Esaie', 'Jeremie', 'Lamentations', 'Ezechiel', 'Daniel', 'Osée', 'Joel', 'Amos', 'Abdias', 'Jonas', 'Michée', 'Nahum', 'Habakkuk', 'Sophonie', 'Aggée', 'Zacharie', 'Malachie', 'Matthieu', 'Marc', 'Luc', 'Jean', 'Actes', 'Romains', '1 Corinthiens', '2 Corinthiens', 'Galates', 'Ephesiens', 'Philippiens', 'Colossiens', '1 Thessaloniciens', '2 Thessaloniciens', '1 Timothée', '2 Timothée', 'Tites', 'Philemon', 'Hebreux', 'Jacques', '1 Pierre', '2 Pierre', '1 Jean', '2 Jean', '3 Jean', 'Jude', 'Apocalypse'],
            chapterList:[50, 40, 27, 36, 34, 24, 21, 4, 31, 24, 22, 25, 29, 36, 10, 13, 10, 42, 150, 31, 12, 8, 66, 52, 5, 48, 12, 14, 3, 9, 1, 4, 7, 3, 3, 3, 2, 14, 4, 28, 16, 24, 21, 28, 16, 16, 13, 6, 6, 4, 4, 5, 3, 6, 4, 3, 1, 13, 5, 5, 3, 5, 1, 1, 1, 22],
        };
        //this.fluxHttpLogin = this.fluxHttpLogin.bind(this);
    }
    readBible() {
      const verseStyle = {
        fontSize:this.state.defaultVersesize,
        color:'white',
      }
      console.log(myBible);
        if (myBible['Testaments'].length > 0 && this.state.isReadBible) {
          console.log(myBible['Text']);
          console.log("Livre :"+this.state.book);
          console.log("Chapitre :"+this.state.chapter);
            return (
                <div className="myFlexReader" contextmenu="mymenu">
                    {myBible['Testaments'][this.state.testament]['Books'][this.state.book]['Chapters'][this.state.chapter]['Verses'].map((t, index) => 
                        <div className="verse">
                                <p style={verseStyle} key={index}>{t['ID']}. {t['Text']}
                                </p>
                        </div>)}
                     <div contextmenu="mymenu">
                        <menu type="context" id="mymenu">
                          <menuitem label="Refresh"></menuitem>
                          <menuitem label="Twitter"></menuitem>
                        </menu>
                      </div>
                    </div>
            )
        }
    }
    chooseBible() {
        if (this.state.clients.length > 0 && this.state.isChooseBible) {
            return (
                <div className="myFlexReader">
                    {this.state.clients.map(client => 
                        <div className="card">
                            <img src={client.pic} className="cardProfil"/>
                            <div className="cardfooter">
                                <h1 key={client.username}>
                                    {client.username}
                                </h1>
                                <li key={client.id}>
                                    {client.id}
                                </li>
                            </div>
                        </div>)}
                    </div>
            )
        }
    }
    chooseBook() {
        if (this.state.clients.length > 0 && this.state.isChooseBook) {
            return (
                <div className="myFlexReader">
                    {this.state.clients.map(client => 
                        <div className="card">
                            <img src={client.pic} className="cardProfil"/>
                            <div className="cardfooter">
                                <h1 key={client.username}>
                                    {client.username}
                                </h1>
                                <li key={client.id}>
                                    {client.id}
                                </li>
                            </div>
                        </div>)}
                    </div>
            )
        }
    }
    choosechapter() {
        if (this.state.clients.length > 0 && this.state.isChooseChapter) {
            return (
                <div className="myFlexReader">
                    {this.state.clients.map(client => 
                        <div className="card">
                            <img src={client.pic} className="cardProfil"/>
                            <div className="cardfooter">
                                <h1 key={client.username}>
                                    {client.username}
                                </h1>
                                <li key={client.id}>
                                    {client.id}
                                </li>
                            </div>
                        </div>)}
                    </div>
            )
        }
    }
    viewNotes() {
        if (this.state.clients.length > 0 && this.state.isChooseNotes) {
            return (
                <div className="myFlexReader">
                    {this.state.clients.map(client => 
                        <div className="card">
                            <img src={client.pic} className="cardProfil"/>
                            <div className="cardfooter">
                                <h1 key={client.username}>
                                    {client.username}
                                </h1>
                                <li key={client.id}>
                                    {client.id}
                                </li>
                            </div>
                        </div>)}
                    </div>
            )
        }
    }

    minusSize= event =>{
      if(this.state.defaultVersesize>1){
        this.setState({defaultVersesize:this.state.defaultVersesize-1});
      } else{

      }
    } 
    plusSize= event =>{
      if(this.state.defaultVersesize<30){
        this.setState({defaultVersesize:this.state.defaultVersesize+1});
      } else{
        
      }
    } 

    nextChap= event =>{
      console.log(this.state.chapter);
      console.log("=>");
      console.log(this.state.chapterList[this.state.book]);


      if(this.state.testament==0){
        if(this.state.chapter+1<this.state.chapterList[this.state.book]){
          this.setState({chapter:this.state.chapter+1});
        } else{
          if(this.state.book<38){
              console.log(this.state.book+1);
              console.log("=>");
              console.log(this.state.chapterList[this.state.book+1]);
            this.setState({book:this.state.book+1, chapter:0});
          } else {
            if(this.state.book==38 && this.state.chapter==(this.state.chapterList[38]-1)){
              console.log(this.state.book);
              console.log("=>");
              console.log(this.state.chapterList[38]-1);
            this.setState({testament:1,book:0, chapter:0});
            }
          } 
        }
      }
      if(this.state.testament==1){
        if(this.state.chapter+1<this.state.chapterList[this.state.book+39]){
          this.setState({chapter:this.state.chapter+1});
        } else{
          if(this.state.book<26){
              console.log(this.state.book+1);
              console.log("=>");
              console.log(this.state.chapterList[this.state.book+1]);
            this.setState({book:this.state.book+1, chapter:0});
          } else {
            if(this.state.book==26 && this.state.chapter==(this.state.chapterList[65]-1)){
              console.log(this.state.book);
              console.log("=>");
              console.log(this.state.chapterList[66]-1);
            this.setState({testament:0,book:0, chapter:0});
            }
          } 
        }
      }

    }
    prevChap= event =>{

      if(this.state.testament==0){
        if(this.state.chapter>0){
          this.setState({chapter:this.state.chapter-1});
        } else{
          if(this.state.book>0){
              console.log(this.state.book-1);
              console.log("=>");
              console.log(this.state.chapterList[this.state.book-1]);
            this.setState({book:this.state.book-1, chapter:this.state.chapterList[this.state.book-1]-1});
          } else {
            if(this.state.testament==0 && this.state.book==0 && this.state.chapter==0){
              console.log(this.state.book);
              console.log("=>");
              console.log(this.state.chapterList[66]);
            this.setState({testament:1,book:26, chapter:this.state.chapterList[65]-1});
            }
          }         
        }
      }
      if(this.state.testament==1){
        if(this.state.chapter>0){
          this.setState({chapter:this.state.chapter-1});
        } else{
          if(this.state.book>0){
              console.log(this.state.book-1);
              console.log("=>");
              console.log(this.state.chapterList[this.state.book-1]);
            this.setState({book:this.state.book-1, chapter:this.state.chapterList[this.state.book-1+39]-1});
          } else {
            if(this.state.book==0 && this.state.chapter==0){
              console.log(this.state.book);
              console.log("=>");
              console.log(this.state.chapterList[this.state.book-1]);
            this.setState({testament:0,book:38, chapter:this.state.chapterList[38]-1});
            }
          }         
        }
      }


    }

      componentDidMount() {
        let currentBible = [];
        fetch('https://www.easyjobnetdemo.be/arch/flutlex/client/getMyClients', {
                method: 'POST',
                body: JSON.stringify({"username": "flulexadmin", "mail": this.state.email, "password": this.state.password})
            })
            .then(response => response.text())
            .then(data => {
                console.log(JSON.parse(data)['code']);
                console.log(JSON.parse(data).code);
                if (JSON.parse(data).code === '1') {
                    console.log(JSON.parse(data)['response']);
                    this.setState(() => ({
                        clients: JSON.parse(data)['response'],
                        failedfetch: false
                    }));
                    this.validateList();
                } else {
                    this.setState(() => ({failedfetch: true}));
                }
            })
    }
    render() {

        const {clients} = this.state.clients; 
        const isReadBible = this.state.isReadBible; 
        const isLoading = this.state.isLoading; 
        const tooltip1 = (<Tooltip id="tooltip1">Naviguez dans la bible</Tooltip>); 
        const tooltip2 = (<Tooltip id="tooltip2">Ecouter la bible</Tooltip>); 
        const tooltip3 = (<Tooltip id="tooltip3">Modifier la Taille du texte</Tooltip>); 
        const tooltip4 = (<Tooltip id="tooltip4">Voir le calendrier</Tooltip>); 
        const tooltip5 = (<Tooltip id="tooltip5">Voir le feu</Tooltip>); 
        return (
          <div>
            <div className="HomeBase"> 
                <div className="lander"> 
                    <div className="myGrid"> 
                        <div id="myContain">
                            <div id="BibleMenu">
                                <div>
                                    <div>
                                        <Button onClick={this.prevChap}>

                                                <KeyboardArrowLeft />

                                        </Button>
                                        <Button>
                                                <b>{this.state.testament==0?this.state.booksListfr[this.state.book]:this.state.booksListfr[this.state.book+39]}</b>
                                        </Button>
                                        <Button>
                                                <b>{this.state.chapter+1}</b>
                                        </Button>
                                        <Button onClick={this.nextChap}>
                                                <KeyboardArrowRight />
                                        </Button>
                                    </div>
                                    <div id="BibleMenuRight">
                                        <Button >
                                              music
                                        </Button>
                                        <Button>
                                                th-list
                                        </Button>
                                        <Button onClick={this.minusSize}>
                                               minus
                                        </Button>
                                        <Button onClick={this.plusSize}>
                                                plus
                                        </Button>
                                        <Button>
                                                calendar
                                        </Button>
                                        <Button>
                                                fire
                                        </Button>
                                    </div>
                                </div>
                            </div>
                            <div className="myFlexContainer">
                                <div>
                                    {this.readBible()}
                                    {this.chooseBible()}
                                    {this.chooseBook()}
                                    {this.choosechapter()}
                                    {this.viewNotes()}
                                </div>
                            </div>
                        </div>
                     </div>
                </div>
            </div>
        </div>
        )
    }
}

export default ReadBible