import React, {Component} from "react";
import myBible from '../json/t_kjv.json'
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp';
import TextFormat from '@material-ui/icons/TextFormat';
import ColoredScrollbars from "../containers/ColoredScrollbars";
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { amber } from '@material-ui/core/colors';

import "./ReadBible.css";

class ReadBible2 extends Component {
      constructor(props) {
        super(props);

        this.state = {
            user: [],
            clients: [],
            testament:0,
            book:1,
            chapter:1,
            verse:1,
            isReadBible:true,
            isLoading:false,
            isChooseBible:true,
            isChooseBook:false,
            isChooseChapter:true,
            isChooseNotes:true,
            defaultVersesize:13,
            searchResult:[],
            searchValue:"",
            nomLivre:'',
            booksListen:['Genesis', 'Exodus', 'Leviticus', 'Numbers', 'Deuteronomy', 'Joshua', 'Judges', 'Ruth', '1 Samuel', '2 Samuel', '1 Kings', '2 Kings', '1 Chronicles', '2 Chronicles', 'Ezra', 'Nehemiah', 'Esther', 'Job', 'Psalms', 'Proverbs', 'Ecclesiastes', 'Song of Solomon', 'Isaiah', 'Jeremiah', 'Lamentations', 'Ezechiel', 'Daniel', 'Hosea', 'Joel', 'Amos', 'Obadiah', 'Jonah', 'Micah', 'Nahum', 'Habakkuk', 'Zephaniah', 'Haggai', 'Zechariah', 'Malachi', 'Matthew', 'Mark', 'Luke', 'John', 'Acts', 'Romans', '1 Corinthians', '2 Corinthians', 'Galatians', 'Ephesians', 'Philippians', 'Colossians', '1 Thessalonians', '2 Thessalonians', '1 Timothy', '2 Timothy', 'Titus', 'Philemon', 'Hebrews', 'James', '1 Peter', '2 Peter', '1 John', '2 John', '3 John', 'Jude', 'Revelation'],
            chapterList:[50, 40, 27, 36, 34, 24, 21, 4, 31, 24, 22, 25, 29, 36, 10, 13, 10, 42, 150, 31, 12, 8, 66, 52, 5, 48, 12, 14, 3, 9, 1, 4, 7, 3, 3, 3, 2, 14, 4, 28, 16, 24, 21, 28, 16, 16, 13, 6, 6, 4, 4, 5, 3, 6, 4, 3, 1, 13, 5, 5, 3, 5, 1, 1, 1, 22],
        };
        //this.fluxHttpLogin = this.fluxHttpLogin.bind(this);
    }
    readBible() {
      const verseStyle = {
        fontSize:this.state.defaultVersesize,
        color:'white',
      }
      console.log(myBible);
        if (myBible['data'].length > 0 && this.state.isReadBible) {
            return (
                <div className="myFlexReader" contextmenu="mymenu" id="divBible">
                    {myBible['data'].filter((d, index) => (d['b']==this.state.book & d['c']==this.state.chapter)).map((t, index) => 
                        <div className="verse">
                                <p style={verseStyle} key={index}><span className="bibleVerse">{t['v']}. {t['t']}</span>
                                </p>
                        </div>)}
                     <div contextmenu="mymenu">
                        <menu type="context" id="mymenu">
                          <menuitem label="Highlight"></menuitem>
                          <menuitem label="Search"></menuitem>
                        </menu>
                      </div>
                    </div>
            )
        }
    }
    goprev(){
        return(
                <div className="myFlexReader" id="divbtPrev">
                    <Button onClick={this.prevChap} id="btPrev">
                        <KeyboardArrowLeft style={{ fontSize: 60, color: amber[700] }} />
                    </Button>
                </div>
            )
    }
    gonext(){
        return(
                <div className="myFlexReader" id="divbtNext">
                    <Button onClick={this.nextChap} id="btNext">
                        <KeyboardArrowRight style={{ fontSize: 60, color: amber[700]}} />
                    </Button>
                </div>
            )
    }
    chooseBible() {
            const verseStyle = {
        fontSize:this.state.defaultVersesize,
        color:'white',
      }
        if (this.state.isChooseBible) {
            return (
                <ColoredScrollbars style={{ height: '100vh'}} className="bookCustomScroll">
                    {this.state.booksListen.map((b, index) => 

                              <p  style={verseStyle} onClick={() => this.gotoBook(index)} className="myBooksList">
                                  <span className="bibleBooks">{b}</span>
                              </p>
                              )}
                    </ColoredScrollbars>
            )
        }
    }
    choosechapter() {
      const verseStyle = {
        fontSize:this.state.defaultVersesize,
        color:'white',
      }
       var rows = [], i = 0, len = this.state.chapterList[this.state.book-1];
      while (++i <= len) rows.push(i);
        if (this.state.isChooseChapter) {
            return (
                <ColoredScrollbars style={{ height: '100vh'}} className="bookCustomScroll2">
                    {rows.map((b, index) => 
                          <p  style={verseStyle} onClick={() => this.gotoChapter(b)} className="myBooksList">
                              <span className="bibleChapter">{b}</span>
                          </p>
                      )}
                    </ColoredScrollbars>
            )
        }
    }

    gotoBook(i){
      this.setState({book:i+1, chapter:1});
    }
  gotoChapter(i){
      this.setState({chapter:i});
    }
    chooseBook() {
        if (this.state.clients.length > 0 && this.state.isChooseBook) {
            return (
                <div className="myFlexReader">
                    {this.state.clients.map(client => 
                        <div className="card">
                            <img src={client.pic} className="cardProfil" alt="client pic"/>
                            <div className="cardfooter">
                                <h1 key={client.username}>
                                    {client.username}
                                </h1>
                                <li key={client.id}>
                                    {client.id}
                                </li>
                            </div>
                        </div>)}
                    </div>
            )
        }
    }
    updbibleSearch(event){
        this.setState({searchValue: event.target.value});
    }
    bibleSearch(event){
        var toSearch = this.state.searchValue.toUpperCase();
        var toget = "<span class=\"highl\">"+toSearch+"</span>";
            var tempSearchBible = myBible['data'].filter((d, index) => (d['t'].toUpperCase().includes(toSearch)>0));
            var searchBible = tempSearchBible.slice(0,20);
            searchBible.forEach(function(v){
                v['t'] = v['t'].replace('Job', toget);
            })
        this.setState({searchResult :searchBible});
    }
    bibleGoto(i1,i2,i3){
        console.log(i1);
        console.log(i2);
        this.setState({book:i1+1, chapter:i2});
    }
    viewNotes() {
              const verseStyle = {
        fontSize:this.state.defaultVersesize,
        color:'black',
      }
        if (this.state.isChooseNotes) {
            return (
                <div className="myFlexReader" id="mySearchRoot">
                            <Paper className="searchroot" elevation={1} id="mySearchPaper">
                              <InputBase className="searchinput" placeholder="Search the Bible" onChange={event =>this.updbibleSearch(event)} id="mySearchInput" onKeyDown={event =>this.keyPress(event)}/>
                              <IconButton className="searchiconButton" onClick={() => this.bibleSearch()} aria-label="Search">
                                <SearchIcon  style={{ color: amber[700]}}/>
                              </IconButton>
                              <Divider className="searchdivider" />
                              {this.state.searchResult.map((res, i) =>
                                    <div key={i} onClick={() => this.bibleGoto(res.b-1, res.c, res.v)}><p className="verseResult" style={verseStyle}>{i+1} {this.state.booksListen[res.b-1]} {res.c}:{res.v} - <span dangerouslySetInnerHTML={{__html:res.t}} /></p></div>
                                )
                              }
                            </Paper>

                    </div>
            )
        }
    }
    keyPress(e){
      if(e.keyCode == 13){
            var toSearch = this.state.searchValue.toUpperCase();
            var toget = "<span class=\"highl\">"+toSearch+"</span>";
            var tempSearchBible = myBible['data'].filter((d, index) => (d['t'].toUpperCase().includes(toSearch)>0));
            var searchBible = tempSearchBible.slice(0,20);
            searchBible.forEach(function(v){
                v['t'] = v['t'].replace('Job', toget);
            })
        this.setState({searchResult :searchBible});
      }
   }
    viewNotes2() {
              const verseStyle = {
        fontSize:this.state.defaultVersesize,
        color:'black',
      }
        if (this.state.isChooseNotes) {
            return (
                <div className="myNotes" style={{}}>
                            <Paper className="searchroot" elevation={1}>
                              <InputBase className="searchinput" placeholder="Search the Bible" onChange={event =>this.updbibleSearch(event)} onKeyDown={this.keyPress}/>
                              <IconButton className="searchiconButton" onClick={() => this.bibleSearch()} aria-label="Search">
                                <SearchIcon />
                              </IconButton>
                              <Divider className="searchdivider" />
                              {this.state.searchResult.map((res, i) =>
                                    <div key={i}><p style={verseStyle}>{i+1} - <span dangerouslySetInnerHTML={{__html:res.t}} /></p></div>
                                )
                              }
                            </Paper>

                    </div>
            )
        }
    }


    selectBook = event => {
        this.gotoBook(this.state.booksListen.indexOf(event.target.value));
      };
    selectChapter = event => {
        this.setState({chapter:event.target.value});
      };

    minusSize= event =>{
      if(this.state.defaultVersesize>1){
        this.setState({defaultVersesize:this.state.defaultVersesize-1});
      } else{

      }
    } 
    plusSize= event =>{
      if(this.state.defaultVersesize<30){
        this.setState({defaultVersesize:this.state.defaultVersesize+1});
      } else{
        
      }
    } 

    nextChap= event =>{
        if(this.state.chapter+1<=this.state.chapterList[this.state.book-1]){
          this.setState({chapter:this.state.chapter+1});
        } else{
            if(this.state.chapter==(this.state.chapterList[this.state.book-1])){
              if(this.state.book<66){
                  this.setState({book:this.state.book+1, chapter:1});
              } else{
                  this.setState({book:1, chapter:1});
              }
            
            }
          
        }
    }
    prevChap= event =>{
        if(this.state.chapter>1){
          this.setState({chapter:this.state.chapter-1});
        } else{
          if(this.state.book>1){
            this.setState({book:this.state.book-1, chapter:this.state.chapterList[this.state.book-2]});
          } else {
            if(this.state.book==1 && this.state.chapter==1){
            this.setState({book:66, chapter:this.state.chapterList[65]});
            }
          }         
        }

    }

      componentDidMount() {
        let currentBible = [];
        fetch('https://www.easyjobnetdemo.be/arch/flutlex/client/getMyClients', {
                method: 'POST',
                body: JSON.stringify({"username": "flulexadmin", "mail": this.state.email, "password": this.state.password})
            })
            .then(response => response.text())
            .then(data => {
                console.log(JSON.parse(data)['code']);
                console.log(JSON.parse(data).code);
                if (JSON.parse(data).code === '1') {
                    console.log(JSON.parse(data)['response']);
                    this.setState(() => ({
                        clients: JSON.parse(data)['response'],
                        failedfetch: false
                    }));
                    this.validateList();
                } else {
                    this.setState(() => ({failedfetch: true}));
                }
            })
    }
    render() {
        const {clients} = this.state.clients; 
        const isReadBible = this.state.isReadBible; 
        const isLoading = this.state.isLoading; 
        const tooltip1 = (<Tooltip id="tooltip1">Naviguez dans la bible</Tooltip>); 
        const tooltip2 = (<Tooltip id="tooltip2">Ecouter la bible</Tooltip>); 
        const tooltip3 = (<Tooltip id="tooltip3">Modifier la Taille du texte</Tooltip>); 
        const tooltip4 = (<Tooltip id="tooltip4">Voir le calendrier</Tooltip>); 
        const tooltip5 = (<Tooltip id="tooltip5">Voir le feu</Tooltip>); 
        var rows = [], i = 0, len = this.state.chapterList[this.state.book-1];
      while (++i <= len) rows.push(i);
        return (
          <div>
            <div className="HomeBase"> 
                <div className="lander"> 
                    <div> 

                            <div id="BibleMenu">
                                <div>
                                    <div>
                                        <Button onClick={this.prevChap}>

                                                <KeyboardArrowLeft style={{ fontSize: 60}} />

                                        </Button>
                                            <Select id="bookselect" value={this.state.booksListen[this.state.book-1]} onChange={this.selectBook} >

                                                {this.state.booksListen.map((b, index) => 
                                                    <MenuItem value={b}>{b}</MenuItem>
                                                )}

                                            </Select>

                                            <Select id="chapterselect" value={this.state.chapter} onChange={this.selectChapter} >

                                                {rows.map((b, index) => 
                                                    <MenuItem value={b}>{b}</MenuItem>
                                                )}

                                            </Select>
                                        <Button onClick={this.nextChap}>
                                                <KeyboardArrowRight style={{ fontSize: 60}}/>
                                        </Button>
                                        <div id="BibleMenuRight">

                                        <Button onClick={this.minusSize}>
                                               <TextFormat /><KeyboardArrowDown />
                                        </Button>
                                        <Button onClick={this.plusSize}>
                                                <TextFormat /><KeyboardArrowUp />
                                        </Button>

                                    </div>
                                    </div>

                                </div>
                            </div>
                            <div className="myFlexContainer">

                                {/*this.chooseBible()*/}
                                {/*this.choosechapter()*/}
                                {this.goprev()}
                                {this.readBible()}
                                {this.gonext()}
                                {/*this.chooseBook()*/}
                                {this.viewNotes()}

                            </div>

                     </div>
                </div>
            </div>
        </div>
        )
    }
}

export default ReadBible2